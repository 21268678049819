/**
 * 登录逻辑链
 */
import { getUserBasicInfo } from '../api/user'

// 超级管理员用户名
const SUPER_ADMIN = "superadmin"

async function loginHandler() {
  const userInfo = await getUserBasicInfo()
  return userInfo
}

export {
  SUPER_ADMIN,
  loginHandler
}