// 获取20位长度的随机标识
export const randomId = () => {
  return (Math.random().toString(36) + Math.random().toString(36))
    .replace(/0\./g, "")
    .slice(0, 20);
};

// 不为未定义或空指针
export function isNotEmpty(value) {
  return value !== null && value !== undefined;
}

/**
 * 解析 pathId 参数
 */
export function parsePathId() {

  // 需兼容 hash模式
  const { pathname } = window.location
  const id = pathname.split('/').pop()

  return id;
}

/**
 * 解析 search 参数
 */
export function parseSearch() {

  // 需兼容 hash模式
  const { href } = window.location
  const searchIndex = href.indexOf('?')
  let search = href.slice(searchIndex)

  if (!search.includes("?")) {
    return {};
  }
  search = search.slice(1);
  const map = {};
  const searchArr = search.split("&");
  searchArr.forEach((i, index) => {
    const [k, v] = i.split("=");
    map[k] = v;
  });
  return map;
}

/**
 * 解析 hash 参数
 */
export function parseHash() {
  // 需兼容 hash模式
  let { hash } = window.location
  hash = hash.slice(1)

  const map = {};
  const hashArr = hash.split("&");
  hashArr.forEach((i, index) => {
    const [k, v] = i.split("=");
    map[k] = v;
  });
  return map;
}

/**
 * 将数组转换成对象（便于快速访问）
 * @param {array} arr arr
 * @param {string} idKey 唯一标识字段 【默认 id】
 */
export function genArrayToMap(arr, idKey = "id") {
  const map = {};
  arr.forEach((i) => {
    const key = i[idKey];
    map[key] = i;
  });
  return map;
}

/**
 * 将数组转换成对象（便于快速访问）
 * @param {array} arr arr
 * @param {string} idKey 唯一标识字段 【默认 id】
 */
export function flatArrDeep(arr = []) {
  return [].concat(...arr.map((i) => [i, ...flatArrDeep(i.children)]));
}

/**
 * 深拷贝
 * @param {object} obj 对象或数组
 */
export function deepClone(obj) {
  //      定义一个变量 并判断是数组还是对象
  var objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object" && obj != null) {
    // 判断obj存在并且是对象类型的时候 因为null也是object类型，所以要单独做判断
    for (var key in obj) {  // 循环对象类型的obj
      if (obj.hasOwnProperty(key)) {  // 判断obj中是否存在key属性
        if (obj[key] && typeof obj[key] === "object") {  //  判断如果obj[key]存在并且obj[key]是对象类型的时候应该深拷贝，即在堆内存中开辟新的内存
          objClone[key] = deepClone(obj[key]);
        } else {  //  否则就是浅复制
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
}

// 节流函数
export const throttle = (fn, delay) => {
  let _timer;
  return function (...args) {
    if (_timer) {
      return;
    } else {
      _timer = setTimeout(() => {
        fn.apply(this, args);
        clearTimeout(_timer);
        _timer = null;
      }, delay);
    }
  };
};

// 防抖函数
export const debounce = (fn, delay, immediate = false) => {
  let _timer;

  function handler(...args) {
    // 是否即刻触发
    if (immediate) {
      fn.apply(this, args);
      immediate = false
      return
    }

    clearTimeout(_timer);
    _timer = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };

  // 清理定时器
  handler.cancel = function () {
    clearTimeout(_timer);
  }

  return handler
};

/** 计算剩余天数 */
export function calcRestDay(date) {
  return Math.floor(
    (new Date(date).getTime() + 24 * 60 * 60 * 1000 - Date.now()) / (60 * 60 * 24 * 1000)
  ) || 0
}

/** 资源格式化到相对路径 */
export function formatResource2RelativePath(fileList) {
  if (!Array.isArray(fileList)) {
    fileList = [fileList]
  }

  return fileList.map(i => i?.relativePath)

}

/** 服务端资源路径格式化至组件数据 */
export function formatCloudResource2ComponentSource(fileList) {
  if (!Array.isArray(fileList)) {
    fileList = [fileList]
  }

  // 捕获资源相对路径
  const reg = RegExp("(?:(?:\.com\/)|(?:\.cn\/))(.+)")

  return fileList.map(url => ({ url, relativePath: url.match(reg)[1] }))

}

/** 判断对象为空 */
export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0
}