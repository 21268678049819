import React, { useEffect, useState } from "react";
import {
  Space,
  Input,
  Menu,
  Button,
  Table,
  Dropdown,
  message,
  Modal,
} from "antd";
import {
  getUserList,
  resetPassword,
  universalRegister,
  updateUserInfo,
  verifyAccountPwd,
} from "../../api/user";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { createInputModal } from "../../core/InputModal";
import UserManageAdd from "../UserManageAdd";
const PAGE_SIZE = 10;

const UserManage = () => {
  // 当前页
  const [page, setPage] = useState(1);
  // 用户列表
  const [userList, setUserList] = useState([]);
  // 关键字
  const [keyword, setKeyword] = useState("");
  // 添加用户弹窗
  const [addUserVisible, setAddUserVisible] = useState(false);
  // 添加用户弹窗
  const [userId, setUserId] = useState("");

  useEffect(() => {
    toGetList();
  }, [keyword]);

  // 用户添加 - 确认回调
  async function onAddUserSubmit(data) {
    const userId = await universalRegister(data);
    message.success(`注册成功:${userId}, 请自行返回`);
    toGetList();
  }

  // 用户添加 - 编辑模式确认回调
  async function onUpdateUserSubmit(id, data) {
    await updateUserInfo(id, data);
    await toGetList();
    closeAddUserModal();
    message.success("已更新");
  }

  // 用户添加 - 弹窗关闭
  function closeAddUserModal() {
    setAddUserVisible(false);
  }

  // 关键字搜索
  async function onKeywordChange(e) {
    const curKeyword = e.target.value;
    setKeyword(curKeyword);
  }

  // 获取用户列表
  async function toGetList() {
    const list = await getUserList({
      keyword,
    });
    list.forEach((i) => (i.key = i.id));
    setUserList(list);
  }

  // 用户添加 - 点击
  async function handleUserAddClick() {
    setAddUserVisible(true);
  }

  // 用户编辑 - 点击
  async function handleEditClick(id) {
    setUserId(id);
    setAddUserVisible(true);
  }

  // 用户删除 - 点击
  async function handleDeleteClick(id) {
    // 二次验证
    // createInputModal({
    //   type: "password",
    //   title: "管理员密码二次校验",
    //   defaultPlaceholder: `请输入账号: ${SUPER_ADMIN} 的密码`,
    //   async onOk(val) {
    //     await verifyAccountPwd(SUPER_ADMIN, val);
    //     await deleteUser(id);
    //     message.success("已删除");
    //     await toGetList();
    //   },
    // });
  }

  // 用户重置密码 - 点击
  async function handleResetPwdClick(id, username) {
    createInputModal({
      title: `重置密码: (${username})`,
      async onOk(val) {
        if (!val) {
          message.warn("密码不能为空");
          return;
        }
        await resetPassword(id, val);
        message.success("已重置");
      },
    });
  }

  // 页码 - 变化
  async function handlePageChange(val) {
    setPage(val);
  }

  const columns = [
    {
      title: "序号",
      width: 60,
      dataIndex: "id",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "账号名",
      width: 140,
      dataIndex: "account",
      render: (text, record, index) => <span co-pr="">{text}</span>,
    },
    {
      title: "手机号",
      width: 140,
      dataIndex: "phone",
      render: (text, record, index) => <span>{text}</span>,
    },
    {
      title: "用户名称",
      width: 140,
      ellipsis: true,
      dataIndex: "username",
      render: (text, record, index) => <span>{text}</span>,
    },
    {
      title: "学校",
      width: 120,
      ellipsis: true,
      dataIndex: "school",
      render: (text, record, index) => <span>{text}</span>,
    },
    {
      title: "班级",
      width: 120,
      ellipsis: true,
      dataIndex: "classroom",
      render: (text, record, index) => <span>{text}</span>,
    },
    {
      title: "生日",
      width: 110,
      dataIndex: "birthday",
      render: (text, record, index) => <span>{text}</span>,
    },
    {
      title: "注册时间",
      width: 110,
      ellipsis: true,
      dataIndex: "register_time",
      render: (text, record, index) => <span>{text.split(" ").shift()}</span>,
    },
    {
      title: "操作",
      // ellipsis: true,
      key: "action",
      render: (text, info) => {
        const { id, username } = info;
        return (
          <Space size="middle">
            <a onClick={() => handleEditClick(id)}>编辑</a>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key="0"
                    onClick={() => handleResetPwdClick(id, username)}
                  >
                    <span co-333="">重置密码</span>
                  </Menu.Item>
                  {/* <Menu.Item
                    key="1"
                    danger
                    onClick={() => handleDeleteClick(id)}
                  >
                    <span>删除</span>
                  </Menu.Item> */}
                </Menu>
              }
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                更多 <DownOutlined />
              </a>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  return (
    <div style={{ maxWidth: "1220px" }}>
      <div mb-20="">
        <Button type="primary" onClick={handleUserAddClick} mr-8="">
          添加用户
        </Button>

        <span fl-ri="">
          <Input
            allowClear
            style={{ width: "auto" }}
            placeholder="输入用户手机号、账号、名称搜索"
            suffix={<SearchOutlined />}
            value={keyword}
            onChange={onKeywordChange}
          />
        </span>
      </div>

      <Table
        size="middle"
        key={[userList]}
        dataSource={userList}
        columns={columns}
        pagination={{
          size: "default",
          defaultCurrent: page,
          onChange: handlePageChange,
          showTotal: (total) => `共 ${total} 个`,
        }}
        expandable={{
          showExpandColumn: false,
        }}
      />

      {/* 弹窗 - 添加用户 */}
      {addUserVisible && (
        <Modal
          title={userId ? "编辑用户" : "添加用户"}
          maskClosable={false}
          onCancel={closeAddUserModal}
          visible={addUserVisible}
          bodyStyle={{ padding: "6px 24px" }}
          footer={null}
        >
          <UserManageAdd
            id={userId}
            onAddSubmit={onAddUserSubmit}
            onUpdateSubmit={onUpdateUserSubmit}
            onClose={closeAddUserModal}
          />
        </Modal>
      )}
    </div>
  );
};

export default UserManage;
