import { Button, Input, message, Tag } from "antd";
import React from "react";
import { useState } from "react";
import { preventDefault } from "../../utils/dom";

const AddControlTag = ({ value = [], onChange }) => {
  const list = value;
  const [inputValue, setInputValue] = useState("");

  function handleTagClose(e, index) {
    e.stopPropagation();
    e.preventDefault();
    const l = list.slice();
    l.splice(index, 1);
    onChange(l);
  }

  function onInputValueChange(e) {
    const { value } = e.target;
    setInputValue(value);
  }

  function handleAdd() {
    if (inputValue) {
      if (list.find((i) => i.value === inputValue)) {
        message.warn("选项重复");
        return;
      }
      const l = [...list, { label: inputValue, value: inputValue }];
      onChange(l);
      setInputValue("");
    }
  }

  function onControlEnumInputKeyUp(e) {
    preventDefault(e);
    if (e.key === "Enter") {
      handleAdd();
    }
  }

  return (
    <div>
      <Input
        style={{ width: "220px" }}
        value={inputValue}
        onChange={onInputValueChange}
        onPressEnter={onControlEnumInputKeyUp}
        placeholder="请填写"
      />

      <Button type="primary" onClick={handleAdd}>
        +
      </Button>

      <div mt-3="">
        {list.map(({ value, label }, index) => (
          <Tag
            key={index}
            color="blue"
            closable
            onClose={(e) => handleTagClose(e, index)}
          >
            {label}
          </Tag>
        ))}
      </div>

      <p fo-si-s="" co-se="" mt-3="">
        - 选项列表,即控件实际可提供项
      </p>
    </div>
  );
};

export default AddControlTag;
