/**
 * @description localStorage 封装（暂不使用cookie)
 */

 const st = window.localStorage

 /** 本地存储封装 */
 class Storage {
   get(key) {
     let value = st.getItem(key)
     try {
       value = JSON.parse(value)
     } catch (e) {
       // console.log(e)
     }
     return value || ''
   }
 
   set(key, value) {
     if (!key) {
       throw new Error('key not find')
     }
     value = value || ''
     try {
       if (typeof value !== 'string') {
         value = JSON.stringify(value)
       }
     } catch (e) {
       // console.log(e)
     }
     return st.setItem(key, value)
   }
 }
 
 // 单例导出
 export default new Storage()
 