/**
 * 全局唯一上传 DOM 封装
 */
import { message } from "antd";

const uploadDOMId = "#upload";

const MAX_SIZE = 200 * 1024 * 1024; // 200MB

// 全局上传变更，事件绑定，采用覆盖方式。
let onUploadChange = null;

/**
 * 唤起上传控件
 * @param {function} cb 上传文件回调
 */
function showUpload(cb) {
  const upload = document.querySelector(uploadDOMId);
  upload.click();

  // 如果事件还在，则取消绑定
  onUploadChange && upload.removeEventListener("change", onUploadChange);

  // 定义新事件
  onUploadChange = async (e) => {
    // DOM重置
    const reset = () => {
      // 重置文件
      e.target.value = "";
      // 解绑事件
      upload.removeEventListener("change", onUploadChange);
      onUploadChange = null;
    };

    let { files } = e.target;

    // 进行文件拦截校验
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const { name, size } = file;
      const filenameSplit = name.split(".");

      if (filenameSplit.length < 1 || !filenameSplit[1]) {
        message.warn(`无法识别文件类型`);
        reset();
        return;
      }

      if (size > MAX_SIZE) {
        message.warn(`文件大小>${MAX_SIZE / 1024 / 1024}mb`);
        reset();
        return;
      }
    }

    // 确认无误，再执行传入的回调
    await cb(e);
    reset();
  };

  upload.addEventListener("change", onUploadChange);
}

export { showUpload };
