/**
 * 找寻父元素
 * @param {*} curDom
 * @param {*} parentSelector
 * @returns
 */
export function findParentDom(curDom, parentSelector) {
  while (curDom) {
    if (parentSelector.startsWith(".")) {
      if (Array.from(curDom.classList).includes(parentSelector.slice(1))) {
        return curDom;
      }
    } else {
      if (curDom.id == parentSelector.slice(1)) {
        return curDom;
      }
    }
    curDom = curDom.parentNode;
  }
}

/**
 * 创建一个下载地址
 * @param {string} url 下载路径绝对地址
 */
export function createDownloadHref(url) {
  const a = document.createElement("a"); // 创建a标签
  a.setAttribute("href", url); // href链接
  a.setAttribute("target", "_blank"); // 新窗口
  a.setAttribute("download", "123.zip"); // download属性
  a.click(); // 自执行点击事件
}

/**
 * 复制内容到剪切板
 */
export function clipboardCopy(content) {
  try {
    // content为要复制的内容
    // 创建元素用于复制
    const ele = document.createElement('input')
    // 设置元素内容
    ele.setAttribute('value', content)
    // 将元素插入页面进行调用
    document.body.appendChild(ele)
    // 复制内容
    ele.select()
    // 将内容复制到剪贴板
    document.execCommand('copy')
    // 删除创建元素
    document.body.removeChild(ele)
    return true
  } catch (err) {
    console.log(err)
    return false
  }
}

export function preventDefault(e) {
  e && e.preventDefault()
}