import { useRef } from "react";
import { debounce } from "../utils";

const useDebounce = (fun, wait, immediate) => {
  const myRef = useRef();

  // 如果是第一次创建
  if (!myRef.current) {
    myRef.current = debounce(fun, wait, immediate);
  } else {
    // 如果是第二次更新 
    // 先清除第一次的定时器
    myRef.current.cancel && myRef.current.cancel();
    // 再重新赋予定时器 immediate 均为 false
    myRef.current = debounce(fun, wait, false);
  }
  return myRef.current;
};

export default useDebounce;
