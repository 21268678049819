import React, { useState, useEffect } from "react";
import "./styles/main-index.scss";
import "./App.scss";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { ConfigProvider, Layout, message } from "antd";
import { flatRoutes, flatConstRoutes, specialRoutes } from "./router";
import MyHeader from "./components/MyHeader";
import MySider from "./components/MySider";
import { getUserBasicInfo } from "./api/user";
import { StateContext } from "./reducer";
import zhCN from "antd/lib/locale/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";

moment.locale("zh-cn");

const { Content } = Layout;

const App = () => {
  // 这里为了不闪屏 默认空，页面无内容，null 才展示登录页
  const [userInfo, setUserInfo] = useState("");

  const specialRoute = isSpecialRoute();

  // 判断是否为特殊路由
  function isSpecialRoute() {
    const { pathname } = window.location;
    if (specialRoutes.find(i=>i.path === pathname)) {
      return true;
    }
    return false;
  }

  useEffect(async () => {
    // 如果是特殊路由，则跳出
    if (specialRoute) {
      return;
    }

    // 检测用户 
    if (userInfo) {
      message.success(`欢迎您,${userInfo.username}`);
      return;
    }

    try {
      const userInfo = await getUserBasicInfo();

      // 必须为超级用户，非超级用户 进入 catch 逻辑 
      if(userInfo.id !== 10000) {
        throw new Error('非超级用户')
      }

      setUserInfo(userInfo);

    } catch (e) {
      setUserInfo(null);
      message.warn("您尚未登录");
    }
  }, [userInfo]);

  console.log(specialRoute)

  return (
    <ConfigProvider locale={zhCN}>
      <StateContext.Provider value={{ userInfo, setUserInfo }}>
        <Router>
          <div className="App">

            {/* 如果是特殊路由 */}
            {specialRoute && (
              <div
                style={{
                  // maxWidth: "var(--screen-width-xl)",
                  height: '100%',
                  width: '100%'
                }}
              >
                <Switch>
                  {specialRoutes
                    .map(({ path, redirect, component }) => (
                      <Route key={path} exact path={path} component={component}>
                        {redirect && <Redirect to={redirect} />}
                      </Route>
                    ))
                    .concat(
                      <Route key="*" path="/">
                        <Redirect to="/" />
                      </Route>
                    )}
                </Switch>
              </div>
            )}

            {/* 如果已登录 */}
            {!specialRoute && userInfo && (
              <Layout>
                <MySider />

                <Layout>
                  <MyHeader />

                  <Content
                    style={{
                      padding: "25px 35px",
                      overflow: "auto",
                      height: "calc(100% - 50px)",
                    }}
                  >
                    <div
                      style={{
                        maxWidth: "var(--screen-width-xl)",
                      }}
                    >
                      <Switch>
                        {flatRoutes
                          .map(({ path, redirect, component }) => (
                            <Route
                              key={path}
                              exact
                              path={path}
                              component={component}
                            >
                              {redirect && <Redirect to={redirect} />}
                            </Route>
                          ))
                          .concat(
                            <Route key="*" path="/">
                              <Redirect to="/" />
                            </Route>
                          )}
                      </Switch>
                    </div>
                  </Content>
                </Layout>
              </Layout>
            )}

            {/* 如果未登录 */}
            {!specialRoute && userInfo === null && (
              <Switch>
                {flatConstRoutes
                  .map(({ path, redirect, component }) => (
                    <Route
                      key={path}
                      exact
                      path={path}
                      component={(props) =>
                        component({
                          ...props,
                          userInfo,
                          setUserInfo,
                        })
                      }
                    >
                      {redirect && <Redirect to={redirect} />}
                    </Route>
                  ))
                  .concat(
                    <Route key="*" path="/">
                      <Redirect to="/" />
                    </Route>
                  )}
              </Switch>
            )}
          </div>

          {/* 全局资源上传 */}
          <input id="upload" type="file" hidden multiple="multiple" />
        </Router>
      </StateContext.Provider>
    </ConfigProvider>
  );
};

export default App;
