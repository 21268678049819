import React from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames/bind";
import styles from "./index.module.scss";
import { RollbackOutlined } from "@ant-design/icons";
const cn = classnames.bind(styles);

const BackBtn = ({ style }) => {
  const history = useHistory();

  function handleBackClick() {
    history.goBack();
  }

  return (
    <div style={style} className={cn("btn")} onClick={handleBackClick}>
      <RollbackOutlined />
      返回
    </div>
  );
};

export default BackBtn;
