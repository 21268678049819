import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import { Menu } from "antd";
import { matchRoutes, routes } from "../../router";
import { useHistory } from "react-router-dom";
import "./index.scss";

const { Sider } = Layout;
const { SubMenu } = Menu;

const MySider = () => {
  const history = useHistory();

  // 实际可渲染的菜单(过滤掉重定向的菜单项，antd Menu 没有实例可控)
  // const menus = routes.filter((i) => (i.redirect && i.children) || !i.redirect);

  // react 可以通过key方式让组件重置，相当于重新设置激活项
  const menus = routes;

  // 下拉菜单 key
  const rootSubmenuKeys = menus.filter((i) => i.children).map((i) => i.path);

  // 展开的 key
  const [openKeys, setOpenKeys] = useState(rootSubmenuKeys);

  // 默认选中 key
  const [defaultSelectKey, setDefaultSelectKey] = useState("");

  // 路由变更
  useEffect(() => {
    function checkRoute2SetDefaultKey() {
      const curRoute = matchRoutes();
      console.log(curRoute);
      // 如果是拥有激活路径，取激活路径
      if (curRoute.activePath) {
        setDefaultSelectKey(curRoute.activePath);
      } else {
        setDefaultSelectKey(curRoute.path);
      }
    }

    checkRoute2SetDefaultKey();

    const historyListener = history.listen((e) => {
      checkRoute2SetDefaultKey();
    });

    return () => historyListener();
  }, []);

  function onOpenChange(keys) {
    setOpenKeys(keys);
  }

  /**
   * 生成菜单项（递归）
   * @param {Array} curMenu 当前菜单
   */
  function genMenu(curMenu) {
    return curMenu.map(({ path, name, icon, children, hidden }) => {
      if (children && children.length) {
        return (
          !hidden && (
            <SubMenu key={path} icon={icon} title={name}>
              {genMenu(children)}
            </SubMenu>
          )
        );
      } else {
        return !hidden && <Menu.Item key={path}>{name}</Menu.Item>;
      }
    });
  }

  /**
   * 菜单被选中
   */
  function handleMenuItemSelect(e) {
    const path = e.key;
    history.push(path);
  }

  return (
    <Sider
      style={{
        backgroundColor: "#fff",
        boxShadow: "0px 0 3px 1px var(--color-shadow-l)",
      }}
      width={170}
    >
      <Menu
        key={defaultSelectKey}
        mode="inline"
        defaultSelectedKeys={[defaultSelectKey]}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        style={{ width: 170 }}
        onSelect={handleMenuItemSelect}
      >
        {genMenu(menus)}

        {/* <SubMenu key="sub1" icon={<MailOutlined />} title="Navigation One">
          <Menu.Item key="1">Option 1</Menu.Item>
          <Menu.Item key="2">Option 2</Menu.Item>
          <Menu.Item key="3">Option 3</Menu.Item>
          <Menu.Item key="4">Option 4</Menu.Item>
        </SubMenu> */}
      </Menu>
    </Sider>
  );
};

export default MySider;
