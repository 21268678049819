/**
 * React-router 路由定义
 */
import ResourceManage from "./containers/ResourceManage";
import K12HomeManage from "./containers/K12HomeManage";
import UserManage from "./containers/UserManage";
import Login from "./containers/Login";
import WxShareCustom from "./containers/WxShareCustom";
import WxPayDetail from "./containers/WxPayDetail";

import PaySubjectManage from "./containers/PaySubjectManage";
import PaySubjectManageDetail from "./containers/PaySubjectManageDetail";
import PaySubjectManageAdd from "./containers/PaySubjectManageAdd";

import PayOrderManage from "./containers/PayOrderManage";
import PayOrderManageRefund from "./containers/PayOrderManageRefund";

import { AppstoreOutlined, HomeOutlined, SettingOutlined, WechatOutlined } from '@ant-design/icons';

/** 特殊路由 */
const specialRoutes = [
  {
    path: '/wx-pay-detail',
    name: '微信支付定制-详情',
    component: WxPayDetail,
  }
]

/** 常量路由 */
const constRoutes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: '登录',
    component: Login
  },
  {
    path: '/setting/wx-share-custom',
    name: '微信分享定制',
    component: WxShareCustom,
  },
]

/** 权限路由（登录后） */
const routes = [
  {
    path: '/',
    redirect: '/bcpt/resource-manage',
    icon: <HomeOutlined />,
    name: '首页'
  },

  {
    path: '/bcpt',
    redirect: '/bcpt/resource-manage',
    icon: <AppstoreOutlined />,
    name: '编程平台',
    children: [
      {
        path: '/bcpt/k12-home-manage',
        name: '首页配置',
        component: K12HomeManage,
      },
      {
        path: '/bcpt/resource-manage',
        name: '资源管理',
        component: ResourceManage,
      }
    ]
  },

  {
    path: '/wxzf',
    redirect: '/wxzf/pay-subject-manage',
    icon: <WechatOutlined />,
    name: '微信支付集成',
    children: [
      {
        path: '/wxzf/pay-subject-manage',
        name: '支付主题管理',
        component: PaySubjectManage,
      },
      {
        path: '/wxzf/pay-subject-manage/add',
        name: '支付主题管理-新增',
        component: PaySubjectManageAdd,
        activePath: '/wxzf/pay-subject-manage',
        hidden: true
      },
      {
        path: '/wxzf/pay-subject-manage/edit',
        name: '支付主题管理-编辑',
        component: PaySubjectManageAdd,
        activePath: '/wxzf/pay-subject-manage',
        hidden: true
      },
      {
        path: '/wxzf/pay-subject-manage/:id',
        name: '支付主题管理-详情',
        component: PaySubjectManageDetail,
        activePath: '/wxzf/pay-subject-manage',
        hidden: true
      },
      
      {
        path: '/wxzf/pay-order-manage',
        name: '支付订单管理',
        component: PayOrderManage,
      },
      {
        path: '/wxzf/pay-order-manage/refund',
        name: '支付主题管理-退款',
        component: PayOrderManageRefund,
        activePath: '/wxzf/pay-order-manage',
        hidden: true
      },
    ]
  },

  {
    path: '/setting',
    redirect: '/setting/user-manage',
    icon: <SettingOutlined />,
    name: '设置',
    children: [
      {
        path: '/setting/user-manage',
        name: '用户管理',
        component: UserManage,
      },
      {
        path: '/setting/wx-share-custom',
        name: '微信分享定制',
        component: WxShareCustom,
      },
    ]
  },

]

/** 扁平化 - 只适用于二级路由 */
const flatConstRoutes = [].concat(...constRoutes.map(i => [i, ...(i.children || []).map(j => ({ ...j, parent: i }))]))

// const flatRoutes = [].concat(...routes.map(i => [i, ...(i.children || [])]))
const flatRoutes = [].concat(...routes.map(i => [i, ...(i.children || []).map(j => ({ ...j, parent: i }))]))

/**
 * 匹配定义的路由，可能是动态路由
 */
function matchRoutes() {
  // 当前位置
  const curPath = window.location.pathname;

  // 当前位置路由
  const curRoute = flatRoutes.find((i) => {
    if (i.path === curPath) {
      return true;
    }
    const curPathSplit = curPath.split("/");
    const curPathSplitLen = curPathSplit.length;

    const pathSplit = i.path.split("/");
    const pathSplitLen = pathSplit.length;

    // 必须是匹配式路由
    if (
      pathSplit.pop().includes(':') &&
      curPathSplitLen === pathSplitLen &&
      curPathSplit.slice(0, curPathSplitLen - 1).join("") ===
      pathSplit.slice(0, pathSplitLen - 1).join("")
    ) {
      return true
    }
  });

  return curRoute
}

export {
  constRoutes,
  flatConstRoutes,

  routes,
  flatRoutes,

  specialRoutes,

  matchRoutes
}