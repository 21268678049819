import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames/bind";
import styles from "./index.module.scss";
// import { orderList as mockOrderList } from "../../config/mockData";
import { Table, Space, message } from "antd";
import {
  getWxpayOrderList,
  setIsReadWxpayOrder,
  setUnreadWxpayOrder,
} from "../../api/wxpayOrder";
import { debounce, isObjectEmpty } from "../../utils";
import useDebounce from "../../hook/useDebounce";
import { defaultPageSize } from "../../config";
const cn = classnames.bind(styles);

const PayOrderTable = (
  {
    subjectId,
    subjectInfo,
    keyword,
    status,
    pageSize: propsPageSize,
    expandRows = false,
  },
  ref
) => {
 
  // 路由
  const history = useHistory();

  // 列表
  const [orderList, setOrderList] = useState([]);
  // 当前页
  const [currentPage, setCurrentPage] = useState(1);

  // 页大小
  const [pageSize, setPageSize] = useState(propsPageSize || defaultPageSize);

  // 页数量
  const [pageTotal, setPageTotal] = useState("");

  const keywordRef = useRef(keyword);

  // 防抖请求
  const debounceToGetList = useDebounce(toGetList, 250, true);

  // hook: 向传入的ref绑定方法或属性
  useImperativeHandle(ref, () => ({
    orderList,
  }));

  // keyword currentPage
  useEffect(async () => {
    keywordRef.current = keyword;

    // 这里必须传入 keyword 不能再 函数内keyword获取
    // 那是按创建节流时刻的keyword来的，永远是 ""
    debounceToGetList();
  }, [keyword, currentPage]);

  async function toGetList() {
    const keyword = keywordRef.current;
    const rs = await getWxpayOrderList({
      page: currentPage,
      pageSize,
      status,
      keyword,
      subjectId: subjectInfo ? subjectInfo.id : subjectId,
    });

    const { list, total } = rs;

    console.log(rs);

    list.key = Math.random();

    setOrderList(list);
    setPageTotal(total);
  }

  // 更新渲染实体+注入特殊key
  // function forceGetList(list) {
  //   // 变更引用
  //   list = [...list];
  //   // 注入特殊的 key
  //   list.key = Math.random();
  //   setOrderList(list);
  // }

  // 分页变化
  function handlePageChange(page) {
    setCurrentPage(page);
  }

  // 已读
  async function handleSetRead(id) {
    await setIsReadWxpayOrder(id);
    toGetList();
    message.success("置为已读");
  }

  // 未读
  async function handleSetUnread(id) {
    await setUnreadWxpayOrder(id);
    toGetList();
    message.success("已置为未读");
  }

  // 前往退款
  function handleRefund(id) {
    history.push(`/wxzf/pay-order-manage/refund?id=${id}`);
  }

  // 前往订单详情
  function handle2OrderDetail(id) {
    // 直接用退款页
    history.push(`/wxzf/pay-order-manage/refund?id=${id}`);
  }

  // 前往主题详情
  function handle2SubjectDetail(id) {
    history.push(`/wxzf/pay-subject-manage/${id}`);
  }

  // 菜单项
  const columns = [
    {
      title: "序号",
      width: 50,
      dataIndex: "id",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "内部订单号",
      dataIndex: "outTradeNo",
      width: 120,
      ellipsis: true,
      render: (text, record, index) => (
        <span
          co-se=""
          ho-te-un=""
          onClick={() => handle2OrderDetail(record.id)}
        >
          {text}
        </span>
      ),
    },
    {
      title: "主题名称",
      ellipsis: true,
      dataIndex: "subjectName",
      render: (text, record, index) => (
        <span
          co-pr=""
          ho-te-un=""
          onClick={() => handle2SubjectDetail(record.subjectId)}
        >
          {text}
        </span>
      ),
    },
    {
      title: "支付人微信名",
      ellipsis: true,
      render: (info, record, index) => {
        const { nickname, headimgurl } = record;
        return (
          <span co-se="" di-fl="">
            <img
              className={cn("wx-avatar")}
              src={headimgurl}
              width="32"
              height="32"
              alt=""
              mr-3=""
            />
            {nickname}
          </span>
        );
      },
    },

    {
      title: "报名人数",
      width: 100,
      dataIndex: "childNumber",
      render: (text, record, index) => <span>{text}</span>,
    },

    {
      title: "付款情况",
      ellipsis: true,
      dataIndex: "status",
      render: (text, record, index) => {
        const { status, total } = record;
        if (status === 1) {
          return <span co-pr="">￥{record.money}</span>;
        }
      },
    },

    {
      title: "付款时间",
      width: 150,
      ellipsis: true,
      dataIndex: "create_date",
      render: (text, record, index) => {
        const [date, time] = text.split(" ");
        return (
          <span co-se="" style={{ textAlign: "right" }}>
            {text}
          </span>
        );
      },
    },
    {
      title: "退款记录",
      ellipsis: true,
      dataIndex: "status",
      render: (text, record, index) => {
        const { status, total, refundList = [] } = record;
        // 需分析 refundList 的情况，再决定视图渲染
        if (refundList.find((i) => i.refundStatus === "PROCESSING")) {
          return <span co-wa="">退款中...</span>;
        } else if (refundList.find((i) => i.refundStatus === "SUCCESS")) {
          // 存款数值计算
          const refundTotal = refundList
            .filter((i) => i.refundStatus === "SUCCESS")
            .reduce((total, j) => total + j.refund, 0);
          // 数值比较
          if (total > refundTotal) {
            return (
              <span co-wa="">
                部分退款
                <br />￥{refundTotal / 100}
              </span>
            );
          } else {
            return <span co-da="">全额退款</span>;
          }
        } else {
          return <span co-se="">/</span>;
        }
      },
    },
    {
      title: "已读",
      width: 80,
      dataIndex: "is_read",
      render: (text, record, index) => {
        if (text) {
          return (
            <span
              co-su=""
              fo-si-l=""
              ho-op=""
              onClick={() => handleSetUnread(record.id)}
            >
              ●
            </span>
          );
        } else {
          return (
            <span co-da="" fo-si-l="">
              ●
            </span>
          );
        }
      },
    },
    {
      title: "操作",
      // ellipsis: true,
      key: "action",
      render: (text, record) => {
        const { id, is_read, status, total, refundList = [] } = record;
        // 存款数值计算
        const refundTotal = refundList
          .filter((i) => i.refundStatus === "SUCCESS")
          .reduce((total, j) => total + j.refund, 0);

        return (
          <Space size="middle">
            {!is_read ? (
              <a onClick={() => handleSetRead(id, record)} co-pr="" ho-te-un="">
                已读
              </a>
            ) : (
              <></>
            )}
            {total > refundTotal ? (
              <a onClick={() => handleRefund(id, record)} co-wa="" ho-te-un="">
                退款
              </a>
            ) : (
              <></>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        key={orderList.key}
        rowKey="id"
        size="middle"
        dataSource={orderList}
        columns={columns}
        expandable={
          expandRows
            ? {
                defaultExpandAllRows: true,
                expandedRowRender: (record) => {
                  // 判断值是否存在
                  if (!subjectInfo) {
                    return;
                  }
                  console.log(subjectInfo)

                  const { childrenFieldList, contactsFieldList } = record;
                  const contactsFieldInfo = contactsFieldList[0];

                  const { name, contactsControlList, childrenControlList } =
                    subjectInfo;

                  console.log(subjectInfo, record);
                  return (
                    <div className={cn("exp")}>
                      {/* 联系人面板 */}
                      <div className={cn("exp-panel")}>
                        <p className={cn("out-navTitle")}>联系人信息</p>
                        <section>
                          {contactsControlList.map(({ name }, index) => {
                            const val = contactsFieldInfo[`field${index}`];
                            const formatVal = Array.isArray(val)
                              ? val.join("、")
                              : val;
                            return (
                              <div key={index} className={cn("exp-row")}>
                                <p>{name}</p>
                                <p>{formatVal}</p>
                              </div>
                            );
                          })}
                        </section>
                      </div>

                      {/* 学生面板 */}
                      <div className={cn("exp-panel", "children")}>
                        <p className={cn("out-navTitle")}>
                          学生信息({childrenFieldList.length})
                        </p>
                        <div>
                          {childrenFieldList.map((childrenfieldInfo, index) => {
                            return (
                              <section
                                key={index}
                                style={{
                                  display: "inline-block",
                                  width: "220px",
                                }}
                              >
                                {childrenControlList.map(({ name }, index) => {
                                  const val =
                                    childrenfieldInfo[`field${index}`];
                                  const formatVal = Array.isArray(val)
                                    ? val.join("、")
                                    : val;
                                  return (
                                    <div key={index} className={cn("exp-row")}>
                                      <p>{name}</p>
                                      <p>{formatVal || "/"}</p>
                                    </div>
                                  );
                                })}
                              </section>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                },
              }
            : null
        }
        pagination={{
          size: "default",
          pageSize,
          total: pageTotal,
          defaultCurrent: currentPage,
          onChange: handlePageChange,
          showTotal: (total) => <span co-se="">数量: {total}</span>,
        }}
      />
    </div>
  );
};

export default forwardRef(PayOrderTable);
