import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames/bind";
import styles from "./index.module.scss";
import BackBtn from "../../components/BackBtn";
import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  UsbFilled,
} from "@ant-design/icons";
import {
  Radio,
  Form,
  Input,
  Button,
  InputNumber,
  Modal,
  message,
  Table,
} from "antd";
import { getWxpayOrder } from "../../api/wxpayOrder";
import { refundOrderWxpayOrder } from "../../api/wxpay";
import { parseSearch } from "../../utils";
import { getWxpayRefundList } from "../../api/wxpayRefund";

const { confirm } = Modal;

const cn = classnames.bind(styles);

const PayOrderManageRefund = () => {
  const history = useHistory();

  // 基础信息
  const [info, setInfo] = useState("");

  // 退款列表
  const [refundList, setRefundList] = useState([]);
  // 已退款金额
  const [refundTotal, setRefundTotal] = useState("");
  // 剩余退款金额
  const [restReFund, setRestRefund] = useState("");

  // 退款信息面板
  const [refundPanelShow, setRefundPanelShow] = useState(false);

  // 预退款金额
  const [refundMoney, setRefundMoney] = useState("");
  // 退款理由
  const [reason, setReason] = useState("");

  const { id } = parseSearch();

  useEffect(() => {
    toGetInfo();
  }, []);

  async function toGetInfo() {
    const info = await getWxpayOrder(id);
    const rs = await getWxpayRefundList({
      page: 1,
      pageSize: 9999,
      orderId: id,
    });
    const refundList = rs.list;

    // 分析可退款金额
    const { total } = info;

    // 退款数值计算
    const refundTotal = refundList
      .filter((i) => i.refundStatus === "SUCCESS")
      .reduce((total, j) => total + j.refund, 0);

    const restReFund = (total - refundTotal) / 100;

    setInfo(info);
    setRefundList(refundList);
    setRefundTotal(refundTotal);
    setRestRefund(restReFund);
  }

  function handleRefundMoneyChange(value) {
    setRefundMoney(value);
  }

  function handleDescChange(e) {
    setReason(e.target.value);
  }

  async function submit() {
    const refund = refundMoney * 100;
    const { total, out_trade_no } = info;

    console.log(out_trade_no, reason, total, refund);

    await confirm({
      title: `确认发起退款？`,
      icon: <ExclamationCircleOutlined />,
      content: `金额: ${refundMoney} 元`,
      async onOk() {
        // ... refund
        try {
          await refundOrderWxpayOrder({
            out_trade_no,
            reason,
            total,
            refund,
            tbl_wxpay_order_id: id,
          });
          message.success("已发起退款，注意刷新页面查看退款状态");
          history.goBack();
        } catch (e) {
          console.log(e)
          message.error(`退款失败,${e?.data?.message}`);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  function handleShowRefundPanel() {
    setRefundPanelShow(true);
  }

  function handleCloseRefundPanel() {
    setRefundPanelShow(false);
  }

  const columns = [
    {
      title: "序号",
      key: "id",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "退款单号",
      dataIndex: "outRefundNo",
      key: "outRefundNo",
    },
    {
      title: "退款时间",
      dataIndex: "createDate",
      key: "createDate",
      render: (text, record, index) => <span>{text}</span>,
    },
    {
      title: "退款金额",
      dataIndex: "refund",
      key: "refund",
      render: (text, record, index) => <span>￥{text / 100}</span>,
    },
    {
      title: "退款原因",
      dataIndex: "reason",
      key: "reason",
      render: (text, record, index) => <span>{text}</span>,
    },
    {
      title: "退款状态",
      dataIndex: "refundStatus",
      key: "refundStatus",
      render: (text, record, index) => {
        if (text === 'PROCESSING') {
          return <span>退款中...</span>;
        } else if (text === 'SUCCESS') {
          return <span co-su="">退款成功</span>;
        } else {
          return <span co-da="">退款失败</span>;
        }
      },
    },
  ];

  return (
    <>
      <BackBtn />
      <div className={cn("ct")} mt-10="">
        <p className="kx-label">1、主题/支付人信息</p>
        <div className={cn("ct-main")}>
          <div style={{ flex: `0 0 340px` }}>
            <div className={cn("ct-item")}>
              <p>主题类型：</p>
              <p>{info.subjectType === 0 ? "活动报名类" : ""}</p>
            </div>
            <div className={cn("ct-item")}>
              <p>主题名称：</p>
              <p co-pr="">{info.subjectName}</p>
            </div>
            <div className={cn("ct-item")}>
              <p>报名截止：</p>
              <p>{info.endDate}</p>
              <p ml-4="" fo-si="" co-se="">
                (剩余{info.restDay}天)
              </p>
            </div>
            <div className={cn("ct-item")}>
              <p>主题金额：</p>
              <p>{info.subjectMoney}元</p>
            </div>
          </div>
          <div>
            <div className={cn("ct-item")}>
              <p>支付人微信名：</p>
              <p di-fl="">
                <img
                  mr-3=""
                  className={cn("wx-avatar")}
                  src={info.headimgurl}
                />
                {info.nickname}
              </p>
            </div>
            <div className={cn("ct-item")}>
              <p>报名学生：</p>
              <p>{info.childList && info.childList.map(i=>i.name).join('、')}</p>
            </div>
            <div className={cn("ct-item")}>
              <p>联系手机：</p>
              <p>{info.phone}</p>
            </div>
            <div className={cn("ct-item")}>
              <p>付款情况：</p>
              <p>
                <span co-pr="">已付款 ￥{info.money}</span>

                {/* <span co-wa="" >
                  部分退款(-224)
                </span>
                <QuestionCircleOutlined
                  ml-6=""
                  style={{ color: "var(--color-primary)" }}
                /> */}
              </p>
            </div>
            <div className={cn("ct-item")}>
              <p>退款记录：</p>
              <div di-fl="">
                {refundList.find((i) => i.status === "PROCESSING") ? (
                  <span co-da-d="">退款中...</span>
                ) : info.money === restReFund ? (
                  <span co-se="">无</span>
                ) : restReFund === 0 ? (
                  <span co-da="">全额退款</span>
                ) : (
                  <span co-wa="">部分退款 ￥{refundTotal / 100}</span>
                )}

                <p
                  className={cn("search-icon")}
                  onClick={handleShowRefundPanel}
                >
                  <SearchOutlined
                    style={{
                      fontSize: "var(--font-size)",
                      color: "#fff",
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>

        <p className="kx-label">2、退款信息填写</p>
        {restReFund !== 0 ? (
          <div pv-20="" style={{ maxWidth: "580px" }}>
            <Form
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              onFinish={submit}
            >
              <Form.Item label="退款方式" name="refundType" required>
                <Radio checked>原路返回</Radio>
              </Form.Item>

              <Form.Item label="退款金额">
                <Form.Item
                  name="refundMoney"
                  noStyle
                  rules={[{ required: true, message: "请输入退款金额" }]}
                >
                  <InputNumber
                    style={{ width: 120 }}
                    min={0}
                    max={restReFund}
                    value={refundMoney}
                    onChange={handleRefundMoneyChange}
                  />
                </Form.Item>
                <span co-se="" ml-3="">
                  元
                </span>
                <p co-pr="">(可退款金额: {restReFund})</p>
              </Form.Item>

              <Form.Item label="退款原因" name="reason">
                <Input.TextArea
                  placeholder="选填"
                  rows={4}
                  value={reason}
                  onChange={handleDescChange}
                />
              </Form.Item>

              <div>
                <Button
                  style={{
                    marginLeft: "80px",
                    marginTop: "20px",
                    width: "240px",
                    height: "42px",
                  }}
                  type="primary"
                  size="middle"
                  htmlType="submit"
                >
                  发起退款
                </Button>
              </div>
            </Form>
          </div>
        ) : (
          <p co-se="" ml-10="">
            已全额退款
          </p>
        )}
      </div>

      {/* 退款列表面板 */}
      <Modal
        title="退款记录"
        visible={refundPanelShow}
        onCancel={handleCloseRefundPanel}
        onOk={handleShowRefundPanel}
        footer={null}
        width="65vw"
      >
        <Table
          rowKey={"outRefundNo"}
          dataSource={refundList}
          columns={columns}
          pagination={false}
        />
      </Modal>
    </>
  );
};

export default PayOrderManageRefund;
