/**
 * 业务模型维护
 */

/**
 * 课程资源模型
 * @param {*} param0 { name, filePath, type, size, parentFolderId?, cover?, authority?, download }
 */
export const lessonResourceModel = ({
  name,
  filePath,
  type,
  size,
  parentFolderId,
  cover,
  authority,
  download,
}) => ({
  name,
  filePath,
  type,
  size,
  parentFolderId,
  cover,
  authority,
  download,
})

/**
 * 课程资源模型 - 文件夹
 * @param {*} param0 { name, parentFolderId?, authority? }
 */
export const lessonResourceFolderModel = ({
  name,
  parentFolderId,
  authority,
}) => ({
  name,
  filePath: "",
  type: "",
  parentFolderId,
  cover: "",
  authority,
})