import OSS from 'ali-oss';
import { OSSInfo } from './secret';

// 实例化阿里云OSS对象
const client = new OSS(OSSInfo);

/**
 * OSSOperator: OSS存储仓库控制器
 */
class OSSOperator {
  constructor() {
    this.client = client;
    this.filename = '' // 提供给中断逻辑
    this.uploadId = ''
  }

  // 上传 cb为progress回调
  upload(filename, file, cb = () => { }) {
    return this.client.multipartUpload(
      filename,
      file,
      {
        partSize: 1 * 1024 * 100,
        progress: (p, e) => {
          if (!this.filename && !this.uploadId && e) {
            this.filename = e.name
            this.uploadId = e.uploadId
          }
          const percent = parseInt(p*100)
          cb(percent)
        },
      }
    );
  }

  abort() {
    // 原本应该获取 当前上传中文件的 name 和 uploadId
    // 考虑成本 以及简单粗暴性 直接调用 abortMultipartUpload
    return this.client.abortMultipartUpload(this.filename, this.uploadId)
  }

}

// 面向过程: 暴露一个初始化OSS的方法
export default () => {
  return new OSSOperator();
}