import { Button, Modal, Select, Form, Radio, Input, Tag, message } from "antd";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { preventDefault } from "../../utils/dom";
import { controlTypeSelect, controlInputPatternSelect } from "./config";
import AddControlTag from "./AddControlTag";
import { randomId } from "../../utils";

const { Option } = Select;

const FormItemLabelCol = { span: 4, offset: 0 };

const AddControl = ({ show, setShow, defaultValue = {}, onControlChange }) => {
  const {
    name = "",
    type = 0,
    required = 1,
    inputPattern = controlInputPatternSelect[0].value,
    enumSelect = [],
  } = defaultValue;

  // 控件 id
  const { id = randomId() } = defaultValue;

  const [form] = Form.useForm();

  const initialValues = {
    controlName: name,
    controlType: type,
    controlRequired: required,
    controlInputPattern: inputPattern,
    controlEnumSelect: enumSelect,
  };
  console.log(initialValues);

  function onFinish(values) {
    let {
      controlName,
      controlType,
      controlRequired,
      controlInputPattern,
      controlEnumSelect,
    } = values;

    /** 已将 /d => [0-9]
     * "\\d"  log 后 => "\\d"  √
     * "\\d"  JSON.stringify 到数据库后会被执行 引号的转义
     *     引号转义后 再 JSON.parse 反序列化时， 导致 \\ 被当做转义符 变成 \d ，反序列化失败。
     * 所以我们提前将 \\ 转成 \\\\
     */
    // if(controlInputPattern) {
    //   controlInputPattern = controlInputPattern.replace(/\\/g, "\\\\");
    // }

    onControlChange({
      id,
      name: controlName,
      type: controlType,
      required: controlRequired,
      inputPattern: controlInputPattern,
      enumSelect: controlEnumSelect,
    });

    onCancel();
  }

  function onCancel() {
    form.resetFields();
    setShow(false);
  }

  return (
    <>
      <Modal
        title="添加控件"
        visible={show}
        footer={null}
        onCancel={onCancel}
        bodyStyle={{ padding: "4px 24px 24px" }}
      >
        <Form
          form={form}
          initialValues={initialValues}
          name="basic"
          onFinish={onFinish}
        >
          <p className="kx-label">1、控件基础信息</p>
          <div ml-12="">
            <Form.Item
              label="控件名称"
              name="controlName"
              labelCol={FormItemLabelCol}
              rules={[{ required: true, message: "请输入" }]}
            >
              <Input
                autoComplete="false"
                style={{ width: "220px" }}
                placeholder="请填写"
                onPressEnter={preventDefault}
              />
            </Form.Item>
            <Form.Item
              label="控件类型"
              name="controlType"
              labelCol={FormItemLabelCol}
              rules={[{ required: true, message: "请选择" }]}
            >
              <Select style={{ width: 120 }}>
                {controlTypeSelect.map(({ value, label }) => (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <p className="kx-label">2、控件更多信息</p>
          <div ml-12="">
            <Form.Item
              label="必填项"
              name="controlRequired"
              labelCol={FormItemLabelCol}
              rules={[{ required: true, message: "请选择" }]}
            >
              <Radio.Group>
                <Radio value={1}>是</Radio>
                <Radio value={0}>否</Radio>
              </Radio.Group>
            </Form.Item>

            {/* 根据控件类型策略显示 */}
            {/* 输入框 */}
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.controlType !== currentValues.controlType
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("controlType") === 0 ? (
                  <Form.Item
                    label="格式校验"
                    name="controlInputPattern"
                    labelCol={FormItemLabelCol}
                    rules={[
                      {
                        required: true,
                        message: "请选择",
                      },
                    ]}
                  >
                    <Select style={{ width: 120 }}>
                      {controlInputPatternSelect.map(({ value, label }) => (
                        <Option key={value} value={value}>
                          {label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="选项添加"
                    name="controlEnumSelect"
                    labelCol={FormItemLabelCol}
                    rules={[
                      {
                        required: true,
                        type: "array",
                        message: "请添加选项",
                      },
                    ]}
                  >
                    <AddControlTag />
                  </Form.Item>
                )
              }
            </Form.Item>
          </div>
          <div te-al-ri="">
            <Button style={{ width: "120px" }} type="primary" htmlType="submit">
              确定
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddControl;
