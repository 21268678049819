import { getWXShareSignature } from '../../api/third';

const ENV = process.env.NODE_ENV

// wx权限注入
function wxInjected() {

  // App mounted 阶段载入
  getWXShareSignature()
    .then(data => {

      if (!data) {
        return;
      }

      const { appId, timestamp, nonceStr, signature } = data
      wx.config({
        debug: ENV === 'production' ? false : true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        // debug:  true,
        appId, // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature,// 必填，签名
        jsApiList: [
          'updateAppMessageShareData', // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
          'updateTimelineShareData', // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
          'onMenuShareTimeline', // 获取“分享到朋友圈”按钮点击状态及自定义分享内容接口（即将废弃）
          'onMenuShareAppMessage', // 获取“分享给朋友”按钮点击状态及自定义分享内容接口（即将废弃）
          'onMenuShareQQ', // 获取“分享到QQ”按钮点击状态及自定义分享内容接口（即将废弃）
          'onMenuShareQZone', // 获取“分享到QQ空间”按钮点击状态及自定义分享内容接口（即将废弃）
          'chooseWXPay', // 微信支付
        ] // 必填，需要使用的JS接口列表
      });
    })
}

/**
 * updateWXShareEntity: 更新微信分享实体 在用户可能点击分享按钮前就先调用
 * @param {object} 分享参数
 * @property title 标题
 * @property desc 描述
 * @property link 链接
 * @property imgUrl 图标
 */
const updateWXShareEntity = ({
  title,
  desc = '',
  link,
  imgUrl
}) => {
  // 更新实体 
  wx.ready(function () {
    const jsApiList = [
      'updateAppMessageShareData', // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
      'updateTimelineShareData', // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
      'onMenuShareTimeline', // 获取“分享到朋友圈”按钮点击状态及自定义分享内容接口（即将废弃）
      'onMenuShareAppMessage', // 获取“分享给朋友”按钮点击状态及自定义分享内容接口（即将废弃）
      'onMenuShareQQ', // 获取“分享到QQ”按钮点击状态及自定义分享内容接口（即将废弃）
      'onMenuShareQZone', // 获取“分享到QQ空间”按钮点击状态及自定义分享内容接口（即将废弃）
    ] // 必填，需要使用的JS接口列表
    jsApiList.forEach(key => {
      wx[key]({
        title,
        desc,
        link,
        imgUrl
      });
    })
  });
}

export {
  wxInjected,
  updateWXShareEntity
}