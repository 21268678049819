import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Checkbox, Button, Radio, DatePicker } from "antd";
import classnames from "classnames/bind";
import {
  checkAccountExist,
  checkPhoneExist,
  getUserAllInfo,
} from "../../api/user";
import { checkPhone, checkEmail } from "../../utils/validate";
import styles from "./index.module.scss";
import moment from "moment";

const cn = classnames.bind(styles);

const UserManageAdd = ({ id, onAddSubmit, onUpdateSubmit, onClose }) => {
  const formRef = useRef(null);
  const rawInfoRef = useRef({});

  useEffect(async () => {
    if (id) {
      // 获取用户信息
      const info = await getUserAllInfo(id);
      rawInfoRef.current = info;
      // 转成数组
      const fieldsList = Object.entries(info).map(([k, v]) => {
        if (k === "birthday") {
          // 处理生日 转成 moment
          if (v) {
            v = moment(v, "YYYY-MM-DD");
          }
        }

        return {
          name: k,
          value: v,
        };
      });

      // 更新表单值
      formRef.current.setFields(fieldsList);
    }
  }, []);

  function onFinish(data) {
    let { birthday } = data;

    // 如果存在生日 需要格式化
    if (birthday && typeof birthday == "object") {
      birthday = birthday.format("YYYY-MM-DD");
    }

    // 检索值 若不存在 均为 undefined
    Object.keys(data).forEach((key) => {
      if (!data[key]) {
        data[key] = undefined;
      }
    });

    const curData = { ...data, birthday };
    if (id) {
      onUpdateSubmit(id, curData);
    } else {
      onAddSubmit(curData);
    }
  }

  function handleBack() {
    onClose();
  }

  const accountRules = [
    {
      required: false,
      async validator(_, account) {
        if (!account) {
          return ;
        }

        if (account.length < 6) {
          return Promise.reject("用户名长度需大于6位");
        }
        try {
          await checkAccountExist(account);
          return Promise.resolve();
        } catch (e) {
          // 如果当前为编辑模式，用户名为自己
          console.log(rawInfoRef.current.account === account);
          if (rawInfoRef.current.account === account) {
            return Promise.resolve();
          }

          return Promise.reject("用户名已存在");
        }
      },
    },
  ];

  const phoneRules = [
    {
      required: false,
      async validator(_, phone) {
        if (!phone) {
          return;
        }

        if (!checkPhone(phone)) {
          return Promise.reject("手机号格式错误");
        }

        try {
          await checkPhoneExist(phone);
          return Promise.resolve();
        } catch (e) {
          // 如果当前为编辑模式，手机号为自己
          if (rawInfoRef.current.phone === phone) {
            return Promise.resolve();
          }

          return Promise.reject("手机号已存在");
        }
      },
    },
  ];

  const emailRules = [
    {
      required: false,
      async validator(_, email) {
        if (!email) {
          return;
        }

        if (!checkEmail(email)) {
          return Promise.reject("邮箱格式错误");
        }
        return Promise.resolve();
      },
    },
  ];

  return (
    <Form
      className={cn("userManageAdd")}
      name="basic"
      initialValues={{}}
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 17 }}
      onFinish={onFinish}
      autoComplete="off"
      ref={formRef}
    >
      {/* 账密信息 */}
      <p className="kx-label">账密信息 (用户名或手机号均可登录)</p>
      <Form.Item label="用户名" name="account" rules={accountRules}>
        <Input />
      </Form.Item>

      <Form.Item label="手机号" name="phone" rules={phoneRules}>
        <Input placeholder="选填，手机号也可以登录" />
      </Form.Item>

      {/* 如果id存在则隐藏密码项 */}
      {!id ? (
        <Form.Item
          label="密码"
          name="password"
          rules={[{ required: true, message: "请输入密码" }]}
        >
          <Input.Password placeholder="请输入密码" />
        </Form.Item>
      ) : (
        <p
          style={{
            padding: "0 42px",
            color: "#fd6c67",
          }}
        >
          密码为敏感信息不可编辑
        </p>
      )}

      {/* 基础信息 */}
      <p className="kx-label">账密信息</p>

      <Form.Item label="角色" name="role">
        <Radio.Group>
          <Radio value={1}>学生</Radio>
          <Radio value={2}>老师</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="性别" name="gender">
        <Radio.Group>
          <Radio value="男">男</Radio>
          <Radio value="女">女</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="昵称" name="username">
        <Input placeholder="选填" />
      </Form.Item>

      <Form.Item label="email" name="email" rules={emailRules}>
        <Input placeholder="选填" />
      </Form.Item>

      <Form.Item name="birthday" label="生日">
        <DatePicker format="YYYY-MM-DD" placeholder="选填" />
      </Form.Item>

      {/* 其他信息 */}
      <p className="kx-label">其他信息</p>

      <Form.Item label="学校" name="school">
        <Input placeholder="选填" />
      </Form.Item>

      <Form.Item label="班级" name="classroom">
        <Input placeholder="选填" />
      </Form.Item>

      <Form.Item wrapperCol={{ span: 24 }}>
        <div di-fl="">
          <Button type="primary" htmlType="submit" style={{ width: "150px" }}>
            保存
          </Button>
          <Button style={{ width: "150px" }} ml-10="" onClick={handleBack}>
            取消
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default UserManageAdd;
