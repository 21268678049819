import createOSS from "./kids-oss-operator";
import { getLessonResourcePath, getTempPath, getTempUrl } from "./config";

class OSSUploader {
  /**
   * 上传资源到 - 资源目录
   * @param {string} filename
   * @param {*} file
   * @param {*} cb
   */
  async uploadToLessonDir(filename, file, cb = () => { }) {
    // 文件上传至OSS
    await createOSS().upload(getLessonResourcePath(filename), file, cb);
  }

  /**
 * 上传资源到 - 临时目录
 * @param {string} filename
 * @param {*} file
 * @param {*} cb
 */
  async uploadToTempDir(filename, file, cb = () => { }) {
    // 文件上传至OSS
    const relativePath = getTempPath(filename)
    const absolutePath = getTempUrl(filename)
    
    file.relativePath = relativePath
    file.absolutePath = absolutePath
    file.filename = relativePath.split('/').pop()
    await createOSS().upload(relativePath, file, cb);
  }
}

export default new OSSUploader();
