/**
 * @description BasicModal 封装
 * 因为模态框弹出 使用的场景比较多，所以单独抽象，避免维护许多 state
 */

import React, { useState, useImperativeHandle, forwardRef } from "react";
import ReactDOM from "react-dom";
import { Modal } from "antd";

const BasicModal = forwardRef(({ children, ...props }, ref) => {
  const { title, okText, onOk } = props;
  const [visible, setVisible] = useState(true);

  // 将当前组件的部分方法暴露，给子组件调用
  useImperativeHandle(ref, () => ({
    emitParentOk: handleOnOk,
  }));

  async function handleOnOk(value) {
    await onOk(value);
    setVisible(false);
  }

  function handleOnCancel() {
    setVisible(false);
  }

  return (
    <Modal
      title={title}
      visible={visible}
      okText={okText}
      cancelText="取消"
      onOk={handleOnOk}
      onCancel={handleOnCancel}
      {...props}
    >
      {children}
    </Modal>
  );
});

function createBasicModal({
  title = "标题",
  okText = "确认",
  onOk = () => {},
  children,
  ...props
}) {
  const div = document.createElement("div");
  document.body.appendChild(div);
  ReactDOM.render(
    <BasicModal title={title} okText={okText} onOk={onOk} {...props}>
      {children}
    </BasicModal>,
    div
  );
}

export { BasicModal as default, createBasicModal };
