import request from '../config/request'

const PREFIX = '/user'

/**
* 用户登录
* @param {string} account 用户账号
* @param {string} password 密码
*/
async function login(account, password) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/login`,
    data: { account, password },
  });

  return rs;
}

/**
* 用户登录密码校验
* @param {string} account 用户账号
* @param {string} password 密码
*/
async function verifyAccountPwd(account, password) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/verifyAccountPwd`,
    data: { account, password },
  });

  return rs;
}

/**
* 用户退出
*/
async function logout() {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/logout`,
  });

  return rs;
}

/**
 * 获取用户基本信息
 */
async function getUserBasicInfo() {
  let rs = await request({
    method: "GET",
    url: `${PREFIX}/getUserBasicInfo`,
  });

  return rs;
}

/**
 * 获取用户列表
* @param {object} params { keyword }
 */
async function getUserList(params) {
  let rs = await request({
    method: "GET",
    url: `${PREFIX}/getUserList`,
    params
  });

  return rs;
}

/**
 * 重置密码
* @param {number} userId userId
* @param {string} password password
 */
async function resetPassword(userId, password) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/resetPassword`,
    data: {
      userId,
      password
    }
  });

  return rs;
}

/**
 * 检测账号是否存在
* @param {string} account 
 */
async function checkAccountExist(account) {
  let rs = await request({
    method: "GET",
    url: `${PREFIX}/checkAccountExist`,
    params: {
      account
    }
  });

  return rs;
}

/**
 * 检测账号是否存在
* @param {string} phone 
 */
async function checkPhoneExist(phone) {
  let rs = await request({
    method: "GET",
    url: `${PREFIX}/checkPhoneExist`,
    params: {
      phone
    }
  });

  return rs;
}

/**
 * 通用注册
* @param {object} data 
 */
async function universalRegister(data) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/universalRegister`,
    data
  });

  return rs;
}

/**
 * 获取用户全部信息
* @param {number} id 
 */
async function getUserAllInfo(id) {
  let rs = await request({
    method: "GET",
    url: `${PREFIX}/getUserAllInfo`,
    params: {
      id
    }
  });

  return rs;
}


/**
 * 修改用户信息
* @param {number} id 
* @param {object} data 
 */
async function updateUserInfo(id, data) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/updateUserInfo?id=${id}`,
    data
  });

  return rs;
}


export {
  login,
  verifyAccountPwd,
  logout,
  getUserBasicInfo,
  getUserList,
  resetPassword,
  checkAccountExist,
  checkPhoneExist,
  universalRegister,
  getUserAllInfo,
  updateUserInfo
}