import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames/bind";
import styles from "./index.module.scss";
import {
  Button,
  notification,
  Table,
  Space,
  Modal,
  Input,
  message,
  Menu,
  Dropdown,
  Pagination,
  Tree,
  Breadcrumb,
} from "antd";
import { showUpload } from "../../utils/dom-upload";
import uploader from "../../utils/oss/kids-resource-uploader";
import {
  LoadingOutlined,
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  FolderOpenOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  deleteLessonResource,
  getAllLessonResource,
  updateLessonResourceOrder,
  removeLessonResource,
  updateLessonResourceParentFolderId,
  updateLessonResourceName,
} from "../../api/lessonResource";
import { getIconPath } from "../../config/path";
import {
  lessonResourceModel,
  lessonResourceFolderModel,
} from "../../config/model";
import { addLessonResource } from "../../api/lessonResource";
import {
  randomId,
  parseSearch,
  genArrayToMap,
  deepClone,
} from "../../utils/index";
import { createDownloadHref, findParentDom } from "../../utils/dom";
import { createInputModal } from "../../core/InputModal";
import ResourceManageDetail from "../ResourceManageDetail";
import { updateWXShareEntity } from "../../utils/weixin/jssdk-core";
import { verifyAccountPwd } from "../../api/user";
import { SUPER_ADMIN } from "../../config/login";

const cn = classnames.bind(styles);

const { confirm } = Modal;

const PAGE_SIZE = 10;

const ResourceManage = () => {
  // 路由
  const history = useHistory();

  // 原始资源列表
  const rawResourceListRef = useRef([]);
  // 原始资对象
  const rawResourceMapRef = useRef({});
  // 现资源列表
  const [resourceList, setResourceList] = useState([]);
  // 面包屑
  const [breadcrumbList, setBreadcrumbList] = useState([]);
  // 当前页
  const [currentPage, setCurrentPage] = useState(1);
  // 关键字
  const [keyword, setKeyword] = useState("");

  // 多选更多菜单显示
  const [showMoreSelectionBtns, setShowMoreSelectionBtns] = useState(false);

  // 移动至页 - 模态框显示
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  // 移动至页 - 模态框数据
  const removePageRef = useRef({
    ids: [],
    targetPage: 0,
    modalTitle: "",
  });

  // 移动至文件夹 - 模态框显示
  const [removeToDirModalVisible, setRemoveToDirModalVisible] = useState(false);
  // 移动至文件夹 - 模态框数据
  const removeToDirPageRef = useRef({
    ids: [],
    parentFolderId: 0,
    modalTitle: "",
  });

  // 资源管理 id
  const [resourceManageId, setResourceManageId] = useState("");

  // 多选ids记录
  const selectionIdsRef = useRef([]);
  // 是否可以上传标志位
  const uploadFileFlagRef = useRef(false);

  // 移动标记
  const moveMarkRef = useRef({ start: "", end: "", isMoving: false });

  // mounted & keyword
  useEffect(async () => {
    await toGetList();
  }, [keyword]);

  // history变化
  useEffect(async () => {
    await toGetList();
  }, [history.location]);

  // 使表格行可拖拽
  // 需监听 resourceList 和 curPage 任意变化，我们均得重新请求服务器
  useEffect(() => {
    // console.log("change", resourceList);
    const rowClass = ".ant-table-row";
    const doms = document.querySelectorAll(rowClass);
    // console.log(doms);

    // 清理激活态按钮
    clearActiveBtns();

    function dragStartHandler(e) {
      const { rowKey } = e.target.dataset;
      // console.log(e.target);
      moveMarkRef.current = {
        ...moveMarkRef.current,
        start: rowKey,
      };
    }

    function dragEnterHandler(e) {
      // 找到对应父元素
      const parentDom = findParentDom(e.target, rowClass);
      const { rowKey } = parentDom.dataset;
      moveMarkRef.current = {
        ...moveMarkRef.current,
        end: rowKey,
      };
    }

    function dragEndHandler(e) {
      // console.log("end", moveMarkRef.current);
      const { start, end, isMoving } = moveMarkRef.current;

      if (isMoving) {
        message.warn("当前有文件正在移动");
        return;
      }

      toMoveFile(start, end);
    }

    doms.forEach((dom) => {
      dom.addEventListener("dragstart", dragStartHandler);
      dom.addEventListener("dragenter", dragEnterHandler);
      dom.addEventListener("dragend", dragEndHandler);
    });

    // 事件解绑
    return () => {
      doms.forEach((dom) => {
        dom.removeEventListener("dragstart", dragStartHandler);
        dom.removeEventListener("dragenter", dragEnterHandler);
        dom.removeEventListener("dragend", dragEndHandler);
      });
    };
  }, [resourceList]);

  // React 是单向响应的，然而 Pagination 只能通过key 变化去触发
  // 这里我们可以监听到 pageChange，然后通过 list 引用变化，让Table 组件去重新渲染
  // 然后 currentPage 在 视图层才会同步
  useEffect(() => {
    forceSetResourceList([...resourceList]);
  }, [currentPage]);

  // 重置激活按钮
  function clearActiveBtns() {
    // 顶部多选激活的按钮面板
    setShowMoreSelectionBtns(false);
  }

  // 更新渲染实体+注入特殊key
  function forceSetResourceList(list) {
    // 变更引用
    list = [...list];
    // 注入特殊的 key
    list.key = Math.random();
    setResourceList(list);
  }

  // 更新分享实体
  async function updateWXShare() {
    // 更新微信实体
    updateWXShareEntity({
      title: "牛了无级别",
      desc: "喜提宝马999W",
      link: "http://xyq.yzz.cn/show/equipment/201109/366979_4.shtml",
      imgUrl: "http://i1.img.969g.com/xyq/imgx2011/09/12/39_101407_ad5a3.jpg",
    });
  }

  // 移动文件 开始文件id 结束文件id
  async function toMoveFile(start, end) {
    // 过滤
    if (start === end) {
      return;
    }
    moveMarkRef.current.isMoving = true;
    await updateLessonResourceOrder(start, end);
    await toGetList();

    message.success("移动成功");
    moveMarkRef.current.isMoving = false;
  }

  // 获取课程资源
  async function toGetList() {
    // 解析数参数
    const { id } = parseSearch();

    const list = await getAllLessonResource();
    let renderList = [];

    // 存储原数组
    rawResourceListRef.current = list;

    // 扁平化结构 并存储
    rawResourceMapRef.current = genArrayToMap(list);

    // 如果有关键字则在原数组中进行筛选
    if (keyword) {
      const { current } = rawResourceListRef;
      const list = current.filter((i) => i.name.includes(keyword));
      forceSetResourceList(list);
      return;
    }

    // 如果资源（目前只能是文件夹）
    if (id) {
      let item = rawResourceMapRef.current[id];
      const dirParentList = [];
      renderList = item.children;

      // 循环生成当前文件夹的父级
      while (item) {
        dirParentList.unshift(item);
        item = rawResourceMapRef.current[item.parentFolderId];
      }

      setBreadcrumbList(dirParentList);
    } else {
      renderList = list.filter((i) => !i.parentFolderId);
      setBreadcrumbList([]);
    }

    // 剔除 children
    renderList = renderList.map((i) => ({
      ...i,
      childrenLength: i.children?.length,
      children: [],
    }));
    forceSetResourceList(renderList);
  }

  // 跳转到最新的页面
  function jump2lastestPage() {
    setCurrentPage(Math.ceil(resourceList.length / PAGE_SIZE));
  }

  /**
   * 文件上传
   * @param {*} e
   */
  async function onFileUploadChange(e) {
    const { files } = e.target;
    const notificationKey = "uploading";

    // 开启标志位
    if (uploadFileFlagRef.current) {
      message.warn("当前有文件正在上传 !");
      return;
    }

    uploadFileFlagRef.current = true;

    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        const { name, size } = file;
        const type = name.split(".").pop();
        const randomName = randomId();
        const filePath = randomName + "." + type;

        // 上传文件
        await uploader.uploadToLessonDir(filePath, file, (progress) => {
          notification.success({
            key: notificationKey,
            icon: <LoadingOutlined style={{ color: "#1890ff" }} />,
            message: `文件正在上传中 ${progress}% (${i + 1}/${
              files.length
            }) ...`,
            duration: 0,
          });
        });

        // 获取父文件ID
        const { id: parentFolderId } = parseSearch();

        // 模型化
        const resourceInfo = lessonResourceModel({
          name,
          size,
          filePath,
          type,
          parentFolderId,
        });

        // 上传文件
        await addLessonResource(resourceInfo);

        // 重新请求资源
        await toGetList();

        // 切到最新页
        jump2lastestPage();
      }
    } catch (e) {
      console.log(e);
    } finally {
      notification.close(notificationKey);
      // 开放标志位
      uploadFileFlagRef.current = false;
    }
  }

  // 点击顶部新建文件夹按钮
  async function handleNavAddFolderBtnClick() {
    updateWXShare();
    // 创建模态框
    createInputModal({
      title: "新建文件夹",
      okText: "新建",
      onOk: onAddFolderOk,
      defaultPlaceholder: "请输入新文件夹名称",
    });
  }

  // 确认新建文件夹
  async function onAddFolderOk(folderName) {
    // 获取父文件ID
    const { id: parentFolderId } = parseSearch();

    if (!folderName) {
      message.warn("名称不允许为空");
      return;
    }

    // 模型化
    const resourceInfo = lessonResourceFolderModel({
      name: folderName,
      parentFolderId,
    });

    // 上传字段
    await addLessonResource(resourceInfo);
    // 重新请求资源
    await toGetList();
    // 跳转最新页
    jump2lastestPage();
  }

  // 点击顶部上传文件按钮
  function handleNavUploadFileBtnClick() {
    // 开启监听
    showUpload(onFileUploadChange);
  }

  // 分页变化
  function handlePageChange(page) {
    clearActiveBtns();
    // 表单更新，副作用重新进行拖拽元素绑定
    setCurrentPage(page);
  }

  // 处理选择项变化
  function handleRowSelectionChange(selectedRowKeys, selectedRows) {
    console.log(selectedRows);
    // 如果长度大于0，说明多选触发
    if (selectedRowKeys.length > 0) {
      setShowMoreSelectionBtns(true);
    } else {
      setShowMoreSelectionBtns(false);
    }

    selectionIdsRef.current = selectedRowKeys;
  }

  // 资源管理点击
  function handleResourceManageClick(id) {
    setResourceManageId(id);
  }

  // 移动文件至文件夹 - 点击（多选）
  function handleSelectionRemoveToDirClick() {
    handleRemoveToDirClick(selectionIdsRef.current);
  }

  // 移动文件至文件夹 - 点击
  function handleRemoveToDirClick(ids) {
    if (typeof ids === "string") {
      // 找到对应的数据
      const item = resourceList.find((i) => i.id === ids);
      // 重置移动页码选择
      removeToDirPageRef.current = {
        ids: [ids],
        parentFolderId: "",
        modalTitle: `《${item.name}》移动至文件夹`,
      };
    } else {
      // 重置移动页码选择
      removeToDirPageRef.current = {
        ids,
        parentFolderId: "",
        modalTitle: `(选中${ids.length}个文件)移动至文件夹`,
      };
    }

    // console.log(removeToDirPageRef.current);

    // 打开移动至文件夹
    setRemoveToDirModalVisible(true);
  }

  // 移动至文件夹模态框 - 确认回调
  async function onRemoveToDirModalOk() {
    const {
      current: { ids, parentFolderId },
    } = removeToDirPageRef;

    // console.log(ids, parentFolderId);

    // 判断1： id 相等，不变更。
    if (ids.includes(parentFolderId)) {
      message.error("资源请不要移至自身，操作已取消");
      return;
    }

    await updateLessonResourceParentFolderId(ids, parentFolderId);

    //
    // 重新请求资源
    await toGetList();
    // 关闭弹窗
    setRemoveToDirModalVisible(false);
  }

  // 移动至文件夹模态框 - 取消回调
  function onRemoveToDirModalCancel() {
    setRemoveToDirModalVisible(false);
  }

  // 移动至文件夹模态框 - 页码选中回调
  function handleRemoveToDirFolderIdChange(selectKeys) {
    const [id] = selectKeys;
    removeToDirPageRef.current.parentFolderId = id;
  }

  // 移动至页 - 点击（多选）
  function handleSelectionRemoveClick() {
    handleRemoveClick(selectionIdsRef.current);
  }

  // 移动至页 - 点击
  function handleRemoveClick(ids) {
    if (typeof ids === "string") {
      // 找到对应的数据
      const item = resourceList.find((i) => i.id === ids);
      // 重置移动页码选择
      removePageRef.current = {
        ids: [ids],
        targetPage: currentPage,
        modalTitle: `《${item.name}》移动至页`,
      };
    } else {
      // 重置移动页码选择
      removePageRef.current = {
        ids,
        targetPage: currentPage,
        modalTitle: `(选中${ids.length}个文件)移动至页`,
      };
    }

    // console.log(removePageRef.current);

    // 打开移动
    setRemoveModalVisible(true);
  }

  // 移动至页模态框 - 确认回调
  async function onRemoveModalOk() {
    const {
      current: { ids, targetPage },
    } = removePageRef;

    // 找到 targetPage 的第一个元素的索引
    const target = resourceList[PAGE_SIZE * (targetPage - 1)];
    // console.log(resourceList, target);
    const targetId = target.id;
    await removeLessonResource(ids, targetId);

    // 重新请求资源
    await toGetList();
    // 关闭弹窗
    setRemoveModalVisible(false);
  }

  // 移动至页模态框 - 取消回调
  function onRemoveModalCancel() {
    setRemoveModalVisible(false);
  }

  // 移动至页模态框 - 页码选中回调
  function handleRemoveTargetPageChange(page) {
    removePageRef.current.targetPage = page;
  }

  // 重命名
  function handleRenameClick(id, name) {
    // 创建模态框
    createInputModal({
      title: "重命名",
      okText: "确认",
      onOk: (newName) => onRenameOk(id, newName),
      defaultValue: name,
    });
  }

  // 下载
  function handleDownloadClick(filePath) {
    createDownloadHref(filePath);
  }

  // 确认重命名
  async function onRenameOk(id, name) {
    if (!name) {
      message.warn("名称不允许为空");
      return;
    }
    await updateLessonResourceName(id, name);
    await toGetList();
  }

  // 确认删除提示信息
  function showDeleteConfirm(content = "") {
    return new Promise((resolve, reject) => {
      confirm({
        title: "确认删除",
        icon: <ExclamationCircleOutlined />,
        content,
        okText: "删除",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          resolve();
        },
        onCancel() {
          reject();
        },
      });
    });
  }

  // 处理多个删除(传参由内存维护)
  async function handleSelectionDeleteClick() {
    const { current: ids } = selectionIdsRef;
    handleDeleteClick(ids);
  }

  /**
   * 处理删除（兼容多选）
   * @param {string} ids ids
   * @param {string} name name
   */
  async function handleDeleteClick(ids, name) {
    // 如果ids 传入为字符串（即单个id)
    if (typeof ids === "string") {
      ids = [ids];
      await showDeleteConfirm(`确认删除《${name}》?`);
    } else {
      // 多选
      await showDeleteConfirm(`确认删除选中的 ${ids.length} 个文件?`);
    }

    // 二次验证
    createInputModal({
      type: "password",
      title: "管理员密码二次校验",
      defaultPlaceholder: `请输入账号: ${SUPER_ADMIN} 的密码`,
      async onOk(val) {
        await verifyAccountPwd(SUPER_ADMIN, val);
        await deleteLessonResource(ids);
        message.success("已删除");
        await toGetList();
      },
    });
  }

  // 文件夹点击
  function handleDirClick(id) {
    go2DirPage(id);
  }

  // 面包屑点击
  function go2DirPage(id = "") {
    // 先清理掉搜索框
    setKeyword("");
    history.push(`/bcpt/resource-manage?id=${id}`);
    clearActiveBtns();
  }

  // 资源管理 子组件 - 取消
  function handleResourceManageCancel() {
    setResourceManageId("");
  }

  // 资源管理 子组件 - 修改成功
  async function handleResourceManageSubmitOk() {
    message.success("已同步");
    await toGetList();
  }

  // 关键字搜索
  async function onKeywordChange(e) {
    const curKeyword = e.target.value;
    setKeyword(curKeyword);
  }

  // 根据类型获取svg图标路径
  function getTypeIconSvg(type) {
    let svgPath = "";
    switch (type) {
      case "":
        svgPath = "ext-folder.svg";
        break;
      case "jpg":
      case "jpeg":
      case "png":
        svgPath = "ext-image.svg";
        break;
      case "pdf":
        svgPath = "ext-pdf.svg";
        break;
      case "ppt":
      case "pptx":
        svgPath = "ext-ppt.svg";
        break;
      case "mp4":
      case "flv":
      case "flv":
        svgPath = "ext-video.svg";
        break;
      case "doc":
      case "docx":
        svgPath = "ext-word.svg";
        break;
      default:
        svgPath = "ext-unknown.svg";
    }
    return getIconPath(svgPath);
  }

  const columns = [
    {
      title: "序号",
      width: 60,
      dataIndex: "id",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "文件名",
      ellipsis: true,
      dataIndex: "name",
      render: (text, record, index) => {
        const { type, id } = record;
        const isDir = !type;
        return (
          <p
            ellipsis=""
            onClick={isDir ? () => handleDirClick(id) : () => {}}
            ho-te-un={isDir ? "" : undefined}
          >
            <img
              ve-al-mi=""
              mr-6=""
              width={24}
              height={24}
              src={getTypeIconSvg(record.type)}
            />
            <span ve-al-mi="">
              {text}
              {/* 文件夹展示文件个数 */}
              {!record.type && (
                <span co-se="" ml-3="">
                  ({record.childrenLength})
                </span>
              )}
            </span>
          </p>
        );
      },
    },
    {
      title: "类型",
      width: 110,
      dataIndex: "type",
      render: (text, record, index) => (
        <span co-se="">{text ? text : "文件夹"}</span>
      ),
    },
    {
      title: "权限类别",
      width: 110,
      dataIndex: "authority",
      render: (type, record) => {
        let text;
        if (type === 0) {
          text = "开放";
          return <span co-se="">{text}</span>;
        } else if (type === 1) {
          text = `授权(${record.allowedUsers?.length})`;
          return <span co-wa="">{text}</span>;
        } else {
          text = "密码";
          return <span co-wa="">{text}</span>;
        }
      },
    },
    // {
    //   title: "开放下载",
    //   width: 110,
    //   dataIndex: "download",
    //   render: (text) => <span>{text}</span>,
    // },
    {
      title: "上传时间",
      width: 180,
      dataIndex: "createDate",
    },
    {
      title: "文件大小",
      ellipsis: true,
      width: 110,
      dataIndex: "size",
    },
    {
      title: "操作",
      // ellipsis: true,
      key: "action",
      render: (text, info) => {
        const { id, filePath, name } = info;
        return (
          <Space size="middle">
            <a onClick={() => handleResourceManageClick(id)}>管理</a>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="0" onClick={() => handleRemoveToDirClick(id)}>
                    <span co-333="">移动至文件夹</span>
                  </Menu.Item>
                  <Menu.Item key="1" onClick={() => handleRemoveClick(id)}>
                    <span co-333="">移动至页</span>
                  </Menu.Item>
                  <Menu.Item
                    key="2"
                    onClick={() => handleRenameClick(id, name)}
                  >
                    <span co-333="">重命名</span>
                  </Menu.Item>
                  {filePath && (
                    <Menu.Item
                      key="3"
                      onClick={() => handleDownloadClick(filePath)}
                    >
                      <span co-pr="">下载</span>
                    </Menu.Item>
                  )}
                  <Menu.Item
                    key="4"
                    danger
                    onClick={() => handleDeleteClick(id, name)}
                  >
                    <span>删除</span>
                  </Menu.Item>
                </Menu>
              }
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                更多 <DownOutlined />
              </a>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  // 治理文件夹格式数据
  function genDirList() {
    // 递归 - 生成子文件夹目录
    function parse2DirList(list = []) {
      const dirList = [];
      // 深拷贝
      list = deepClone(list);
      list.forEach((item) => {
        item.key = item.id;
        item.title = item.name;
        item.children = parse2DirList(item.children);
        if (!item.type) {
          dirList.push(item);
        }
      });
      return dirList;
    }

    // 递归 - 将 children 设置 disabled: true
    function setItemPropsRecurrenceChildren(item, props) {
      Object.entries(props).forEach(([k, v]) => {
        item[k] = v;
      });
      item.children.forEach((i) => setItemPropsRecurrenceChildren(i, props));
    }

    // 递归 - 发现 id 是否在 ids中，并设置不可选
    function findIdSetDisabled(list = []) {
      list.forEach((i) => {
        if (ids.includes(i.id)) {
          setItemPropsRecurrenceChildren(i, {
            disabled: true,
          });
        }
        if (i.children) {
          findIdSetDisabled(i.children);
        }
      });
    }

    // 深拷贝 - 拿到全部文件夹
    const readyDirList = deepClone(rawResourceListRef.current).filter(
      (i) => !i.type && !i.parentFolderId
    );

    // 根据 removeToDirPageRef ids 需要 disabled
    const { ids } = removeToDirPageRef.current;

    // 递归发现与设置
    findIdSetDisabled(readyDirList);

    return parse2DirList(readyDirList);
  }

  // 文件夹可移动树
  const removeToDirTreeData = genDirList();
  console.log(resourceList);

  return (
    <div className={cn("resourceManage")}>
      <div>
        <Button
          type="primary"
          style={{
            color: "#666",
            background: "#ffc766",
            borderColor: "#666",
          }}
          onClick={handleNavAddFolderBtnClick}
          mr-8=""
        >
          新建文件夹
        </Button>
        <Button type="primary" onClick={handleNavUploadFileBtnClick} mr-8="">
          上传文件
        </Button>
        <span className="kx-tip" co-se="">
          文件的顺序即编程平台 资源管理 ，展示的顺序
        </span>

        <span fl-ri="">
          <Input
            allowClear
            style={{ width: "auto" }}
            placeholder="输入名称搜索"
            suffix={<SearchOutlined />}
            value={keyword}
            onChange={onKeywordChange}
          />
        </span>

        <div di-fl="" sp="" mt-10="">
          {/* 面包屑 */}
          <Breadcrumb>
            <Breadcrumb.Item ho-co-pr="" onClick={() => go2DirPage("")}>
              / 根目录
            </Breadcrumb.Item>
            {breadcrumbList.map(({ name, id }) => (
              <Breadcrumb.Item
                key={id}
                ho-co-pr=""
                onClick={() => go2DirPage(id)}
              >
                {name}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>

          {/* 多选更多菜单显示 */}
          {showMoreSelectionBtns && (
            <div>
              <span
                lv-ri=""
                pr-8=""
                mr-8=""
                ho-op=""
                ho-te-un=""
                co-pr=""
                onClick={handleSelectionRemoveToDirClick}
              >
                移动至文件夹
              </span>
              <span
                lv-ri=""
                pr-8=""
                mr-8=""
                ho-op=""
                ho-te-un=""
                co-pr=""
                onClick={handleSelectionRemoveClick}
              >
                移动至页
              </span>
              <span
                ho-op=""
                ho-te-un=""
                co-da=""
                onClick={handleSelectionDeleteClick}
              >
                删除
              </span>
            </div>
          )}
        </div>
      </div>

      <div pv-20="" style={{ maxWidth: "1220px" }}>
        <Table
          key={resourceList.key}
          size="middle"
          dataSource={resourceList}
          columns={columns}
          pagination={{
            size: "default",
            defaultCurrent: currentPage,
            onChange: handlePageChange,
            showTotal: (total) => `共 ${total} 个`,
          }}
          rowSelection={{
            type: "checkbox",
            onChange: handleRowSelectionChange,
          }}
          expandable={{
            showExpandColumn: false,
          }}
        />
      </div>

      {/* 移动至页 - 模态框 */}
      <Modal
        title={removePageRef.current.modalTitle}
        visible={removeModalVisible}
        onOk={onRemoveModalOk}
        onCancel={onRemoveModalCancel}
      >
        <Pagination
          key={Math.random()}
          defaultCurrent={currentPage}
          total={resourceList.length}
          onChange={handleRemoveTargetPageChange}
        />
      </Modal>

      {/* 移动至文件夹 - 模态框 */}
      <Modal
        title={removeToDirPageRef.current.modalTitle}
        visible={removeToDirModalVisible}
        onOk={onRemoveToDirModalOk}
        okText="移动"
        cancelText="取消"
        onCancel={onRemoveToDirModalCancel}
      >
        <>
          <p co-da="" pb-10="">
            (不选中，则表示移至根目录)
          </p>
          <Tree
            showLine={{ showLeafIcon: false }}
            defaultExpandAll
            defaultSelectedKeys={["0-0-0"]}
            onSelect={handleRemoveToDirFolderIdChange}
            treeData={removeToDirTreeData}
          />
        </>
      </Modal>

      {/* 管理资源 - 模态框 */}
      {resourceManageId && (
        <ResourceManageDetail
          id={resourceManageId}
          onClose={handleResourceManageCancel}
          onSubmit={handleResourceManageSubmitOk}
        />
      )}
    </div>
  );
};

export default ResourceManage;
