import React, { useState, useEffect } from "react";
import classnames from "classnames/bind";
import styles from "./index.module.scss";
import { Modal, Input, Select } from "antd";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import PayOrderTable from "./table";

const cn = classnames.bind(styles);

const { confirm } = Modal;
const { Option } = Select;

const PayOrderManage = () => {
  // 关键字
  const [keyword, setKeyword] = useState("");
  // 订单状态
  const [status, setStatus] = useState(1);

  // 关键字搜索
  async function onKeywordChange(e) {
    const curKeyword = e.target.value;
    setKeyword(curKeyword);
  }

  return (
    <div className={cn("ct")}>
      <div style={{ maxWidth: "1200px" }}>
        {/* <Select defaultValue="0" style={{ width: 130 }} disabled>
          <Option value="0">活动报名类</Option>
        </Select> */}

        {/* <span ml-10=""> */}
        {/* <Select allowClear style={{ width: 130 }} placeholder="选择订单状态">
          <Option value="0">已付款</Option>
          <Option value="1">已部分退款</Option>
          <Option value="2">已全额退款</Option>
        </Select> */}
        {/* </span> */}

        <span ml-10="">
          <Input
            allowClear
            style={{ width: "auto" }}
            placeholder="请输入主题名称搜索"
            suffix={<SearchOutlined />}
            value={keyword}
            onChange={onKeywordChange}
          />
        </span>
      </div>

      <div pv-20="" style={{ maxWidth: "1220px" }}>
        <PayOrderTable keyword={keyword} />
      </div>
    </div>
  );
};

export default PayOrderManage;
