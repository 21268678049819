/**
 * @description 模态框封装
 */

import {
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Modal } from "antd";

const { confirm } = Modal;

/**
 * 确认框（阻塞异步）
 * @param {*} title 
 * @param {*} content 
 * @param {*} param2 okText, cancelText
 * @returns 
 */
export function confirmAsync(title, content, {
  okText = '确认',
  cancelText = '取消',
} = {}) {
  return new Promise((resolve, reject) => {
    confirm({
      title,
      content,
      okText,
      cancelText,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        resolve();
      },
      onCancel() {
        reject();
      },
    });
  });
}