import React, { useState } from "react";
import { Input, Button } from "antd";
import { login, getUserBasicInfo } from "../../api/user";
import { message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

// 全局配置
message.config({
  maxCount: 1
})

const Login = ({ isLogin, setUserInfo, ...props }) => {


  const [account, setAccount] = useState("superadmin");
  const [password, setPassword] = useState("");

  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  async function handleLogin() {
    if (!password.length) {
      message.warn("请输入密码!");
      return;
    }

    let userInfo = null;

    try {
      await login(account, password);
      userInfo = await getUserBasicInfo();
    } catch (e) {
      message.warn(e.msg);
      return;
    }

    setUserInfo(userInfo);
  }

  function handleAccountChange(e){
    setAccount(e.target.value)
  }

  return (
    <div
      di-fl=""
      ce=""
      style={{
        height: "100vh",
        background: "#f1f8ff",
      }}
    >
      <section
        style={{
          width: "480px",
          marginTop: "-22vh",
          paddingBottom: "60px",
          boxShadow: "0 2px 8px 3px var(--color-shadow-l)",
        }}
        bg-te=""
        pa-45=""
      >
        <p pv-15="" fo-si-ll="" co-pr-d="">
          K12编程平台 - 管理后台
        </p>
        <p pv-15="" pb-30="">
          <span fo-si-ll="">欢迎使用</span>
          <span co-se="" fo-si-s="" pl-10="">
            (目前仅支持超级账号登录)
          </span>
        </p>

        <Input
          mt-15=""
          pa-15=""
          placeholder="请输入账号"
          value={account}
          onChange={handleAccountChange}
          disabled
        />

        <div mt-15="">
          <Input.Password
            style={{
              padding: "15px",
            }}
            placeholder="请输入密码"
            type="password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            onChange={handlePasswordChange}
            onPressEnter={handleLogin}
          />
        </div>

        <Button
          style={{
            height: "48px",
          }}
          mt-30=""
          block
          type="primary"
          size="large"
          onClick={handleLogin}
        >
          登录
        </Button>
      </section>
    </div>
  );
};

export default Login;
