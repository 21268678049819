import request from "../config/request";

const PREFIX = "/work";

/**
 * 获取首页信息
 */
async function getPublishWorkWithCondition({
  page,
  pageSize,
  keyword,
  type,
  tag,
  order,
}) {
  let rs = await request({
    method: "GET",
    url: `${PREFIX}/getPublishWorkWithCondition`,
    params: {
      page,
      pageSize,
      keyword,
      type,
      tag,
      order,
    },
  });

  return rs;
}

export { getPublishWorkWithCondition };
