import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { wxInjected } from "./utils/weixin/jssdk-core";
// import VConsole from 'vconsole'

// 注入 jssdk
wxInjected();

// 移动端调试
// let vConsole = new VConsole() // 初始化

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById('root')
);