import request from '../config/request'
import { apiBaseUrl } from '../config/env'

const PREFIX = '/third'

/**
 * 获取公众号登录h5地址
 */
async function getWxAurhorizeUrl(redirect) {
  let rs = await request({
    method: "GET",
    url: `${PREFIX}/getWxAurhorizeUrl`,
    params: {
      redirect
    }
  });

  return rs;
}

/**
* 获取微信公众号签名
*/
async function getWXShareSignature() {
  let url2 = location.href.split('#')[0]

  // let rs = await request({
  //   method: "GET",
  //   url: `${PREFIX}/getWXShareSignature`,
  //   params: {
  //     url
  //   }
  // });

  // return rs;

  const url = `${apiBaseUrl}${PREFIX}/getWXShareSignature?url=${url2}`

  const { data } = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  }).then(response => response.json());

  return data;

}

/**
 * 注册微信用户信息
 *   服务端保存 微信用户
 * @param {string} code code
 */
async function registerWXUserInfoByFuWuHaoCode(code) {

  let rs = await request({
    method: "GET",
    url: `${PREFIX}/registerWXUserInfoByFuWuHaoCode`,
    params: {
      code
    }
  });

  return rs;

  // const url = `${apiBaseUrl}${PREFIX}/registerWXUserInfoByFuWuHaoCode?code=${code}`

  // const { data } = await fetch(url, {
  //   method: 'GET',
  //   headers: { 'Content-Type': 'application/json' },
  //   credentials: 'include'
  // }).then(response => response.json());

  // return data;

}

/**
 * 微信统一下单触发
 * @param {string} openid openid
 */
async function wxpayUnifiedorder(openid) {

  let rs = await request({
    method: "GET",
    url: `${PREFIX}/wxpayUnifiedorder`,
    params: {
      openid
    }
  });

  return rs;
}

export {
  getWxAurhorizeUrl,
  getWXShareSignature,
  registerWXUserInfoByFuWuHaoCode,
  wxpayUnifiedorder
}

