import React, { useEffect, useState, forwardRef } from "react";
import { Form, Input, Radio, Button, Select, Checkbox } from "antd";
import AddControl from "./AddControl";
import { controlMode, formControlMode, formFieldName } from "./config";
import classnames from "classnames/bind";
import styles from "./index.module.scss";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const cn = classnames.bind(styles);

const defaultMode = formControlMode.edit;
const formItemSpan = 7;

const FormControl = (
  { mode = defaultMode, defaultValue, onChange = () => {}, className },
  ref
) => {
  // 控件显示
  const [showAddControl, setShowAddControl] = useState(false);
  // 控件状态（新增 编辑 删除）
  const [addControlProps, setAddControlProps] = useState({});
  // 控件列表
  const [controlList, setControlList] = useState(defaultValue);

  // 当控件列表变化
  useEffect(() => {
    onChange(controlList);
  }, [controlList]);

  // 点击 - 控件新增按钮
  async function handleShowAddControlClick() {
    setAddControlProps({
      mode: controlMode.add,
    });
    setShowAddControl(true);
  }

  // 点击 - 控件编辑
  function handleControlEdit(index) {
    const controlInfo = controlList[index];
    console.log(controlInfo);
    setAddControlProps({
      mode: 1,
      index,
      ...controlInfo,
    });

    setShowAddControl(true);
  }

  // 变化 - 控件变化
  function onControlChange(info) {
    const { mode, index } = addControlProps;

    console.log(info, addControlProps);

    // 判断控件类型
    if (mode === controlMode.add) {
      setControlList([...controlList, info]);
    } else if (mode === controlMode.edit) {
      const l = controlList.slice();
      l[index] = info;
      setControlList(l);
    }
  }

  // 点击 - 控件删除
  function handleControlDel(index) {
    const l = controlList.slice();
    l.splice(index, 1);
    setControlList(l);
  }

  function handleEmitValidate() {
    ref
      .validateFields()
      .then((values) => {
        console.log(values);
      })
      .catch((errorInfo) => console.log(errorInfo));
  }

  const isEditMode = mode === formControlMode.edit;

  return (
    <div>
      <Form form={ref} name="basic" className={className} autoComplete="false">
        {controlList.map(
          ({ name, type, required, inputPattern, enumSelect }, index) => (
            <div key={index} className={cn("formItemRow")}>
              {isEditMode && (
                <span className={cn("formItemRow-order")}>{index + 1}</span>
              )}

              <Form.Item
                labelCol={{ span: formItemSpan }}
                label={name}
                name={formFieldName + index}
                valuePropName={"value"}
                rules={[
                  {
                    required,
                    type: type === 3 ? "array" : "string",
                    pattern: inputPattern ? inputPattern : null,
                    message: `校验${name}格式错误`,
                  },
                ]}
                style={{
                  position: "relative",
                  zIndex: 1,
                  pointerEvents: isEditMode ? "none" : "all",
                }}
              >
                {type === 0 ? (
                  <Input placeholder={required ? "请输入" : "选填"} />
                ) : type === 1 ? (
                  <Select placeholder={required ? "请选择" : "选填"}>
                    {enumSelect.map(({ label, value }, index) => (
                      <Select.Option key={index} value={value}>
                        {label}
                      </Select.Option>
                    ))}
                  </Select>
                ) : type === 2 ? (
                  <Radio.Group options={enumSelect}></Radio.Group>
                ) : type === 3 ? (
                  <Checkbox.Group options={enumSelect}></Checkbox.Group>
                ) : (
                  <></>
                )}
              </Form.Item>
              {isEditMode && (
                <div className={cn("formItemRow-col")}>
                  <div>
                    <EditOutlined
                      style={{
                        fontSize: "20px",
                        color: "var(--color-primary)",
                      }}
                      ml-6=""
                      ho-op=""
                      onClick={() => handleControlEdit(index)}
                    />
                    <DeleteOutlined
                      style={{ fontSize: "20px", color: "var(--color-danger)" }}
                      ml-6=""
                      ho-op=""
                      onClick={() => handleControlDel(index)}
                    />
                  </div>
                </div>
              )}
            </div>
          )
        )}

        {isEditMode && (
          <div te-al-ri="">
            <Button
              type="primary"
              size="medium"
              ghost
              onClick={handleShowAddControlClick}
            >
              新增 +
            </Button>
          </div>
        )}
      </Form>

      {/* 测试用 */}
      {/* <Button onClick={handleEmitValidate}>测试触发</Button> */}

      {/* 自定义控件 */}
      {showAddControl && (
        <AddControl
          defaultValue={addControlProps}
          show={showAddControl}
          setShow={setShowAddControl}
          onControlChange={onControlChange}
        />
      )}
    </div>
  );
};

export default forwardRef(FormControl);
