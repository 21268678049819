import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { routes, flatRoutes, matchRoutes } from "../../router";

const MyBreadcrumb = () => {
  const history = useHistory();

  const [breadcrumbList, setBreadcrumbList] = useState([]);

  // 路由变更
  useEffect(() => {
    // 执行一次
    setBreadcrumbList(genBreadcrumb());

    const historyListener = history.listen((e) => {
      setBreadcrumbList(genBreadcrumb());
    });

    return () => historyListener();
  }, []);

  /**
   * 生成一个面包屑
   */
  function genBreadcrumb() {
    // 列表
    const breadcrumbList = [];

    // 头是首页
    const breadHeader = routes[0];

    const curRoute = matchRoutes();

    // 递归找父级
    function findAndUnshiftParentRoute(r = {}) {
      const { parent } = r;
      if (parent) {
        const parentRoute = flatRoutes.find((i) => i.path === parent.path);
        breadcrumbList.unshift(parentRoute);
        return findAndUnshiftParentRoute(parentRoute);
      }
    }
    // 注入当前路由
    breadcrumbList.unshift(curRoute);

    // 注入父路由
    findAndUnshiftParentRoute(curRoute);

    // 注入首页
    breadcrumbList.unshift(breadHeader);

    console.log(breadcrumbList);
    return breadcrumbList;
  }

  return (
    <Breadcrumb>
      {breadcrumbList.map(({ path, name }) => (
        <Breadcrumb.Item key={path}>
          <Link to={path}>{name}</Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default MyBreadcrumb;
