import React, { useState } from "react";
import { Modal } from "antd";

const OpenResource = ({ children, type = "image", src, ...props }) => {
  const [show, setShow] = useState(false);

  function handleClose(e) {
    setShow(false);
    e.stopPropagation();
  }

  function handleShow(e) {
    setShow(true);
    e.stopPropagation();
  }

  return (
    <div onClick={handleShow}>
      {type === "image" ? (
        <Modal
          title="图片展示"
          visible={show}
          onCancel={handleClose}
          footer={null}
          width="fit-content"
          style={{ maxWidth: "65vw" }}
        >
          <img src={src} style={{ maxWidth: "65vw", maxHeight:"65vh",objectFit:"contain" }}></img>
        </Modal>
      ) : (
        <></>
      )}

      {children}
    </div>
  );
};

export default OpenResource;
