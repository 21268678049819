/** 控件模式 */
export const controlTypeSelect = [
  {
    value: 0,
    label: "输入框",
  },
  {
    value: 1,
    label: "下拉菜单",
  },
  {
    value: 2,
    label: "单选控件",
  },
  {
    value: 3,
    label: "复选控件",
  },
];

/** 控件输入框模式 正则 */
export const controlInputPatternSelect = [
  {
    value: ".*",
    label: "无",
  },
  {
    value: "^1[3|4|5|7|8]\[0-9]{9}$",
    label: "电话",
  },
  {
    value: "^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$",
    label: "邮箱",
  },
  {
    value: "^\[0-9]{17}[0-9X]$",
    label: "身份证",
  },
]

/** 控件面板 模式 */
export const controlMode = {
  /** 添加模式 */
  add: 0,
  /** 编辑模式 */
  edit: 1
}

/** 表单控件列表 展示模式 */
export const formControlMode = {
  /** 编辑模式 */
  edit: 0,
  /** 使用模式 */
  using: 1
}

/** 表单字段命名 */
export const formFieldName = "field"

/** 测试控件用字段 */
export const mockControlList = [
  {
    id: "1",
    name: "姓名",
    type: 0,
    required: 1,
    inputPattern: ".*",
    enumSelect: [],
  },
  {
    id: "2",
    name: "学校",
    type: 1,
    required: 1,
    inputPattern: ".*",
    enumSelect: [{
      value: "浙江万里学院",
      label: "浙江万里学院"
    }],
  },
  {
    id: "3",
    name: "性别",
    type: 2,
    required: 1,
    inputPattern: ".*",
    enumSelect: [{
      value: "男",
      label: "男"
    }, {
      value: "女",
      label: "女"
    }],
  },
  {
    id: "4",
    name: "选择爱好",
    type: 3,
    required: 1,
    inputPattern: ".*",
    enumSelect: [{
      value: "扑克",
      label: "扑克"
    }, {
      value: "飞牌",
      label: "飞牌"
    }],
  },
]
