/**
 * @description 生成二维码
 */

import QRCode from "qrcode";

/**
 * 字符串转成二维码
 * @param {string} str
 * @returns
 */
export function text2QRCode(str) {
  return new Promise((resolve, reject) => {
    QRCode.toDataURL(str, { margin: 0 }, function (err, url) {
      if (err) {
        reject(err);
        return;
      }
      resolve(url);
    });
  });
}
