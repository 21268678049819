// 开发环境或上线环境区分对待
const ENV = process.env.NODE_ENV; // 环境参数

// OSS地址
let AGENT_HOST, TEMP_PATH,
  LESSON_RESOURCE_PATH

if (ENV === 'development') {
  AGENT_HOST = 'https://shou-upload-test.oss-cn-hangzhou.aliyuncs.com'; // 资源根地址
  TEMP_PATH = 'temp';
  LESSON_RESOURCE_PATH = 'lesson_resource';

} else {
  AGENT_HOST = 'https://kaisiao-users-bucket.oss-cn-hangzhou.aliyuncs.com'; // 资源根地址
  TEMP_PATH = 'temp';
  LESSON_RESOURCE_PATH = 'lesson_resource';
}

// 解析资源路径
const resolve = (dirname) => {
  return dirname;
}

// 绝对路径 - 资源目录
// const getLessonResourceUrl = (file) => {
//   return `${AGENT_HOST}/${LESSON_RESOURCE_PATH}/${file}`;
// }

// 绝对路径 - 临时目录
const getTempUrl = (file) => {
  return `${AGENT_HOST}/${TEMP_PATH}/${file}`;
}

// 解析OSS路径
const getTempPath = (file) => {
  return `${resolve(TEMP_PATH)}/${file}`;
}
const getLessonResourcePath = (avatar) => {
  return `${resolve(LESSON_RESOURCE_PATH)}/${avatar}`;
}

export {
  // getLessonResourceUrl,
  getTempUrl,
  getTempPath,
  getLessonResourcePath
};