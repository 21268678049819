
import request from "../config/request";

const PREFIX = "/wxpay";


/**
 * 微信统一下单触发
 * @param {string} openid 用户微信openid
 * @param {string} subjectId 主题id
 */
async function payUnifiedOrderWxpaySubject({
  openid,
  subjectId,
  amount,
  env
}) {

  let rs = await request({
    method: "POST",
    url: `${PREFIX}/payUnifiedOrderWxpaySubject`,
    data: {
      openid,
      subjectId,
      amount,
      env
    }
  });

  return rs;
}


/**
 * 发起退款
 * @param {*} param0
 */
async function refundOrderWxpayOrder(data) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/refundOrderWxpayOrder`,
    data,
  });

  return rs;
}

export {
  refundOrderWxpayOrder,
  payUnifiedOrderWxpaySubject
};
