import React, { useContext } from "react";
import { Layout } from "antd";
import MyBreadcrumb from "../MyBreadcrumb";
import { Dropdown } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { logout } from "../../api/user";
import { StateContext } from "../../reducer";

const { Header } = Layout;

const MyHeader = () => {
  const { userInfo, setUserInfo } = useContext(StateContext);

  const { username } = userInfo;

  async function handleLogout() {
    await logout();
    setUserInfo(null);
  }

  return (
    <Header
      style={{
        position: "relative",
        backgroundColor: "#fff",
        height: "50px",
        lineHeight: "50px",
        padding: "0 24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "2px 0 3px 1px var(--color-shadow-l)",
      }}
    >
      {/* 面包屑 */}
      <MyBreadcrumb />

      {/* 当前账号 */}
      <Dropdown
        overlay={() => (
          <div
            onClick={handleLogout}
            style={{
              background: "#fff",
              border: "1px solid #ddd",
            }}
          >
            <p pa-10="" ho-co-pr="">
              退出登录
            </p>
          </div>
        )}
      >
        <p
          ho-op=""
          className="ant-dropdown-link"
          onClick={(e) => e.preventDefault()}
        >
          {username} <CaretDownOutlined />
        </p>
      </Dropdown>
    </Header>
  );
};

export default MyHeader;
