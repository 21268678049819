import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames/bind";
import styles from "./index.module.scss";
import { DeleteFilled, EditFilled, PlusCircleFilled } from "@ant-design/icons";
import {
  addPublishTopWork,
  delPublishTopWork,
  getPublishTopWorkMap,
  movePublishTopWork,
  updatePublishTopWork,
} from "../../api/public";
import { icon1, icon2 } from "./icon";
import { message, Modal, Select } from "antd";
import { debounce } from "../../utils";
import { getPublishWorkWithCondition } from "../../api/work";
import { confirmAsync } from "../../core/modal";

const { Option } = Select;

const cn = classnames.bind(styles);

const K12HomeManage = () => {
  const [pageInfo, setPageInfo] = useState({});
  const [workSelectInfo, setWorkSelectInfo] = useState({
    mode: 0, // 0 新增 ,1 变更
    visible: false,
    key: "",
    id: "",
    newId: "",
  });
  const [works, setWorks] = useState([]);
  // 移动标记
  const moveMarkRef = useRef({ start: "", end: "", isMoving: false });

  useEffect(async () => {
    toGetList();
  }, []);

  async function toGetList() {
    const info = await getPublishTopWorkMap();
    setPageInfo(info);
  }

  // 下拉搜索框 - 触发搜索
  const onWorkSelectSearch = debounce(async (val) => {
    const info = await getPublishWorkWithCondition({
      page: 1,
      pageSize: 100,
      keyword: val,
    });
    setWorks(info.list);
  }, 200);

  // 下拉搜索框 - 选中
  function onWorkSelectChange(val) {
    console.log(val);
    setWorkSelectInfo({
      ...workSelectInfo,
      newId: val,
    });
  }

  // 下拉搜索框 - 提交
  async function workSelectSubmit() {
    const { key, id, newId, mode } = workSelectInfo;

    // 变更模式
    if (mode) {
      if (!newId) {
        await confirmAsync("", "当前未选择变更作品，是否空置？");
      }
      await updatePublishTopWork({ key, id, newId });
    } else {
      // 新增模式
      if (!newId) {
        message.warn("请选择一个作品");
        return;
      }
      await addPublishTopWork({ key, id: newId });
    }

    await toGetList();
    message.success("已变更");
    handleWorkSelectClear();
  }

  // 置顶作品编辑
  function handleWorkEditClick(key, id, name) {
    console.log(id);
    setWorkSelectInfo({
      mode: 1, // 编辑
      visible: true,
      key,
      id,
      name,
    });
  }

  // 置顶作品删除
  async function handleWorkDelClick(key, id, name) {
    console.log(id);
    await confirmAsync("", `删除当前作品:《${name}》`);
    await delPublishTopWork({
      key,
      id,
    });
    await toGetList();
    message.success("已删除");
    handleWorkSelectClear();
  }

  // 置顶作品取消
  function handleWorkSelectClear() {
    setWorkSelectInfo({
      visible: false,
      key: null,
      id: null,
      name: null,
    });
  }

  // 新增作品 注意 featured 和 latest
  function handleWorkAddClick(key) {
    setWorkSelectInfo({
      ...workSelectInfo,
      mode: 0, // 新增
      visible: true,
      key,
    });
    console.log(workSelectInfo);
  }

  // 拖拽 - 开始
  function dragStartHandler(key, id) {
    moveMarkRef.current = {
      ...moveMarkRef.current,
      start: {
        id,
        key,
      },
    };
  }

  // 拖拽 - 进入
  function dragEnterHandler(key, id) {
    // 找到对应父元素
    moveMarkRef.current = {
      ...moveMarkRef.current,
      end: {
        key,
        id,
      },
    };
  }

  // 拖拽 - 结束
  async function dragEndHandler() {
    console.log("end", moveMarkRef.current);
    const { start, end, isMoving } = moveMarkRef.current;

    if (start.key === end.key && start.id === end.id) {
      message.warn("当前文件为自身");
      return;
    }

    if (isMoving) {
      message.warn("当前有文件正在移动");
      return;
    }

    // ...api
    await movePublishTopWork({
      startKey: start.key,
      startId: start.id,
      endKey: end.key,
      endId: end.id,
    });
    await toGetList();
    moveMarkRef.current = {};
  }

  // 重组一下数据结构便于传递
  const { featuredList = [], latestList = [] } = pageInfo;

  const mapList = [
    {
      title: "精选作品（配置）",
      key: "featuredList",
      icon: icon1,
      list: featuredList,
    },
    {
      title: "新作推荐（配置）",
      key: "latestList",
      icon: icon2,
      list: latestList,
    },
  ];

  return (
    <div>
      <Modal
        key={workSelectInfo.visible}
        okText={workSelectInfo.mode ? "变更" : "添加"}
        cancelText="取消"
        onOk={workSelectSubmit}
        onCancel={handleWorkSelectClear}
        visible={workSelectInfo.visible}
      >
        {workSelectInfo.mode ? (
          <p co-se="" mv-6="">
            当前作品:
            <span ml-3="" co-se-d="">
              {workSelectInfo.name}
            </span>
          </p>
        ) : (
          <p co-pr="">添加作品:</p>
        )}

        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder={
            workSelectInfo.mode
              ? "请输入作品名称搜索以变更"
              : "请输入作品名称搜索"
          }
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={onWorkSelectSearch}
          onChange={onWorkSelectChange}
          notFoundContent={null}
        >
          {works.map(({ id, name, username, create_time }) => (
            <Option key={id} value={id}>
              - {name} [{username}]<span co-se=""> {create_time}</span>
            </Option>
          ))}
        </Select>
      </Modal>

      {mapList.map(({ key, icon, title, list }) => (
        <div mb-20="" key={title}>
          {/* 精选作品配置 */}
          <p
            fo-si-l=""
            co-se-d=""
            di-fl=""
            pv-6=""
            mb-8=""
            style={{
              borderBottom: "1px solid #ccc",
            }}
          >
            <img src={icon} width="25" mr-6="" />
            {title}
          </p>
          <section>
            {list.map(
              (
                { id, username, cover_path, avatar, name, create_time },
                index
              ) => (
                <div
                  key={index}
                  style={{
                    width: "290px",
                    height: "290px",
                    verticalAlign: "top",
                    userSelect: "none",
                    WebkitUserDrag: "element",
                  }}
                  className={cn("panel")}
                  di-in-bl=""
                  pa-10=""
                  bo-ra-5=""
                  mr-16=""
                  mb-16=""
                  onDragStart={() => dragStartHandler(key, id)}
                  onDragEnter={() => dragEnterHandler(key, id)}
                  onDragEnd={dragEndHandler}
                >
                  <div className={cn("panel__icons")}>
                    <EditFilled
                      className={cn("anticon")}
                      style={{
                        color: "var(--color-primary)",
                        fontSize: "42px",
                      }}
                      onClick={() => handleWorkEditClick(key, id, name)}
                    />
                    <DeleteFilled
                      className={cn("anticon")}
                      style={{
                        marginLeft: "20px",
                        color: "var(--color-danger)",
                        fontSize: "42px",
                      }}
                      onClick={() => handleWorkDelClick(key, id, name)}
                    />
                  </div>

                  {cover_path && (
                    <img
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                      }}
                      src={cover_path}
                      alt=""
                    />
                  )}

                  <p fo-si="" pv-6="" co-pr-d="" style={{ height: "36px" }}>
                    {name}
                  </p>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "6px 0",
                      borderTop: "1px solid #ccc",
                    }}
                  >
                    <img height="24" bo-ra="" mr-8="" src={avatar} alt="" />
                    <span
                      fo-si-s=""
                      ellipsis=""
                      di-in-bl=""
                      style={{
                        maxWidth: "100px",
                      }}
                    >
                      {username}
                    </span>
                    <span fo-si-s="" fl-ri="" co-se="">
                      {create_time}
                    </span>
                  </p>
                </div>
              )
            )}

            <div ho-op="" onClick={() => handleWorkAddClick(key)}>
              <PlusCircleFilled
                style={{
                  color: "var(--color-primary)",
                  fontSize: "42px",
                }}
              />
            </div>
          </section>
        </div>
      ))}
    </div>
  );
};

export default K12HomeManage;
