import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames/bind";
import styles from "./index.module.scss";
import {
  Button,
  Table,
  Space,
  Modal,
  Input,
  Menu,
  Dropdown,
  Select,
  message,
} from "antd";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
// import { subjectList } from "../../config/mockData";
import { ENV } from "../../config/env";
import { clipboardCopy } from "../../utils/dom";
import {
  closeWxpaySubject,
  getWxpaySubjectList,
  publishWxpaySubject,
  recoverWxpaySubject,
} from "../../api/wxpaySubject";
import { defaultPageSize } from "../../config";
import useDebounce from "../../hook/useDebounce";

const cn = classnames.bind(styles);

const { confirm } = Modal;
const { Option } = Select;

const PAGE_SIZE = 10;

const PaySubjectManage = () => {
  // 路由
  const history = useHistory();

  // 主题列表
  const [subjectList, setSubjectList] = useState([]);
  // 当前页
  const [currentPage, setCurrentPage] = useState(1);
  // 页数量
  const [pageTotal, setPageTotal] = useState("");
  // 主题类型
  const [type, setType] = useState(0);
  // 关键字
  const [keyword, setKeyword] = useState("");

  const keywordRef = useRef(keyword);

  // 防抖请求
  const debounceToGetList = useDebounce(toGetList, 250, true);

  // mounted
  useEffect(async () => {
    keywordRef.current = keyword;

    debounceToGetList();
  }, [currentPage, keyword]);

  async function toGetList() {
    const keyword = keywordRef.current;

    const rs = await getWxpaySubjectList({
      page: currentPage,
      type,
      keyword,
    });

    const { list, total } = rs;

    console.log(list);

    setSubjectList(list);
    setPageTotal(total);
  }

  // 创建支付主题
  function handleCreateSubject() {
    history.push(`/wxzf/pay-subject-manage/add`);
  }

  // 编辑支付主题
  function handleEditSubject(id) {
    history.push(`/wxzf/pay-subject-manage/edit?mode=update&id=${id}`);
  }

  // 管理主题
  function handleSubjectManageClick(id) {
    history.push(`/wxzf/pay-subject-manage/${id}`);
  }

  // 复制链接
  function handleCopySubjectLink(id) {
    const url = `${window.location.origin}/wx-pay-detail?id=${id}`;
    const rs = clipboardCopy(url);
    if (rs) {
      message.success(`链接复制成功，请在手机端访问`);
    } else {
      alert(`复制异常，请手动复制：${url}`);
    }
  }

  // 发布主题
  async function handlePublishSubjectClick(id) {
    await publishWxpaySubject(id);
    toGetList();
    message.success("已发布，允许用户付款");
  }

  // 关闭主题
  async function handleCloseSubjectClick(id) {
    await closeWxpaySubject(id);
    toGetList();
    message.error("已关闭，用户无法查看主题");
  }

  // 恢复主题
  async function handleRecoverSubjectClick(id) {
    await recoverWxpaySubject(id);
    toGetList();
    message.success("已恢复，允许用户付款");
  }

  // 分页变化
  function handlePageChange(page) {
    setCurrentPage(page);
  }

  // 关键字搜索
  async function onKeywordChange(e) {
    const curKeyword = e.target.value;
    setKeyword(curKeyword);
  }

  // 菜单项
  const columns = [
    {
      title: "序号",
      width: 60,
      dataIndex: "id",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "主题名称",
      ellipsis: true,
      dataIndex: "name",
      render: (text, record, index) => (
        <span co-pr="" ho-te-un="" onClick={() => handleEditSubject(record.id)}>
          {text}
        </span>
      ),
    },
    {
      title: "主题金额(元)",
      width: 80,
      dataIndex: "total",
      render: (text, record, index) => <span co-wa="">{text / 100}</span>,
    },
    {
      title: "主题发布时间",
      width: 120,
      dataIndex: "publishDate",
      render: (text, record, index) => (
        <span co-se="">{text?.slice(0, 11)}</span>
      ),
    },
    {
      title: "主题报名截止",
      width: 120,
      dataIndex: "endDate",
      render: (text, record, index) => (
        <span co-se-d="">{text.slice(0, 11)}</span>
      ),
    },
    {
      title: "主题状态",
      ellipsis: true,
      width: 150,
      dataIndex: "status",
      render: (status, record, index) => {
        const { restDay } = record;
        if (status === 0) {
          return <span co-se="">○ 未发布</span>;
        } else if (status === 1) {
          if (restDay >= 0) {
            return (
              <span co-su="">
                ● 进行中
                {record.restDay >= 0 ? (
                  <span co-se="" pl-3="">
                    (剩{record.restDay}天)
                  </span>
                ) : (
                  <></>
                )}
              </span>
            );
          } else {
            return <span co-se="">- 已截止</span>;
          }
        } else if (status === -1) {
          return <span co-da="">- 已关闭</span>;
        }
      },
    },
    {
      title: "已报名人数",
      ellipsis: true,
      width: 90,
      dataIndex: "existTotal",
      render: (text, record, index) =>
        text,
    },
    {
      title: "人数限制",
      width: 90,
      dataIndex: "maxPeople",
      render: (maxPeople, record, index) => {
        if (!maxPeople) {
          return <span co-se="">不限制</span>;
        } else {
          return <span co-se="">{maxPeople}</span>;
        }
      },
    },
    {
      title: "操作",
      // ellipsis: true,
      key: "action",
      render: (text, info) => {
        const { id, status } = info;

        return (
          <Space size="middle">
            <a onClick={() => handleSubjectManageClick(id, info)}>人员管理</a>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key="2"
                    onClick={() => handleEditSubject(id, info)}
                  >
                    <span co-333="">编辑</span>
                  </Menu.Item>

                  <Menu.Item
                    key="1"
                    onClick={() => handleCopySubjectLink(id, info)}
                  >
                    <span co-pr="">复制链接</span>
                  </Menu.Item>

                  {status === 0 ? (
                    <Menu.Item
                      key="3"
                      onClick={() => handlePublishSubjectClick(id, info)}
                    >
                      <span co-su="">发布主题</span>
                    </Menu.Item>
                  ) : status === 1 ? (
                    <Menu.Item
                      key="3"
                      danger
                      onClick={() => handleCloseSubjectClick(id, info)}
                    >
                      <span co-da="">关闭主题</span>
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      key="3"
                      onClick={() => handleRecoverSubjectClick(id, info)}
                    >
                      <span co-su="">恢复主题</span>
                    </Menu.Item>
                  )}
                </Menu>
              }
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                更多 <DownOutlined />
              </a>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  return (
    <div className={cn("resourceManage")}>
      <div style={{ maxWidth: "1200px" }}>
        <span>
          <Input
            allowClear
            style={{ width: "auto" }}
            placeholder="请输入主题名称搜索"
            suffix={<SearchOutlined />}
            value={keyword}
            onChange={onKeywordChange}
          />
        </span>

        <span fl-ri="">
          <Button type="primary" onClick={handleCreateSubject} mr-8="">
            创建支付主题
          </Button>
        </span>
      </div>

      <div pv-20="" style={{ maxWidth: "1220px" }}>
        <Table
          key={subjectList.key}
          rowKey="id"
          size="middle"
          dataSource={subjectList}
          columns={columns}
          pagination={{
            size: "default",
            pageSize: defaultPageSize,
            total: pageTotal,
            defaultCurrent: currentPage,
            onChange: handlePageChange,
          }}
        />
      </div>
    </div>
  );
};

export default PaySubjectManage;
