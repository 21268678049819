import React, { useEffect, useRef, useState } from "react";
import {
  getLessonResource,
  updateLessonResource,
} from "../../api/lessonResource";
import { Input, message, Modal, Radio, Select } from "antd";
import { PlusSquareFilled, CloseOutlined } from "@ant-design/icons";
import classnames from "classnames/bind";
import styles from "./index.module.scss";
import { getUserList } from "../../api/user";
import { debounce, throttle } from "../../utils";

const { Option } = Select;

const cn = classnames.bind(styles);

const ResourceManageDetail = ({ id, onClose, onSubmit }) => {
  const [visible, setVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const [lessonInfo, setLessonInfo] = useState({});

  const [selectUserId, setSelectUserId] = useState(null);

  const allUsers = useRef([]);

  useEffect(async () => {
    // 获取全部用户，用于友好显示
    const uList = await getUserList();
    allUsers.current = uList;

    const info = await getLessonResource(id);

    info.allowedUsers.forEach((id, index) => {
      info.allowedUsers[index] = uList.find((i) => i.id === id);
    });

    console.log(info);
    setVisible(true);
    setLessonInfo(info);
  }, [id]);

  // 取消
  function onCancel() {
    onClose();
    setVisible(false);
  }

  // 保存
  async function submit() {
    // 过滤出合法调整项
    let { id, name, authority, allowedUsers, password } = lessonInfo;

    // 治理特殊数据
    allowedUsers = allowedUsers.map((i) => i.id);

    const params = {
      name,
      authority,
      allowedUsers,
      password,
    };

    await updateLessonResource(id, params);

    // 触发父组件变更情况
    await onSubmit();

    // 再触发一次取消
    onCancel();
  }

  // 处理名称变化
  function handleNameChange(e) {
    setLessonInfo({
      ...lessonInfo,
      name: e.target.value,
    });
  }

  // 处理权限变化
  function handleAuthorityChange(e) {
    setLessonInfo({
      ...lessonInfo,
      authority: e.target.value,
    });
  }

  // 处理密码变化
  function handlePasswordChange(e) {
    setLessonInfo({
      ...lessonInfo,
      password: e.target.value,
    });
  }

  // 处理用户搜索 - 变化
  const onUserSelectSearch = debounce(async (keyword) => {
    if (!keyword) {
      setUsers([]);
      return;
    }

    const userList = await getUserList({
      keyword,
    });

    setUsers(userList);
  }, 200);

  // 处理用户搜索 - 列表点击
  function onUserSelectChange(id) {
    setSelectUserId(id);
  }

  // 用户新增点击
  function handleAllowedUserAdd() {
    const { allowedUsers = [] } = lessonInfo;

    if (!selectUserId) {
      message.warn("请先选择用户");
      return;
    }

    if (allowedUsers.find((i) => i.id === selectUserId)) {
      message.warn("用户已授权");
      return;
    }

    const selectUser = users.find((i) => i.id === selectUserId);

    allowedUsers.push(selectUser);

    // 同步至数据
    setLessonInfo({
      ...lessonInfo,
      allowedUsers,
    });

    // 清理
    setSelectUserId(null);
    setUsers([]);
  }

  // 用户删除
  function handleAllowedUserDel(id) {
    const allowedUsers = lessonInfo.allowedUsers.filter((i) => i.id !== id);
    // 同步至数据
    setLessonInfo({
      ...lessonInfo,
      allowedUsers,
    });
  }

  // 解构参数
  let { type, name, authority, password, allowedUsers } = lessonInfo;

  type = !type ? "文件夹" : type;
  allowedUsers = !allowedUsers ? [] : allowedUsers;

  return (
    <Modal
      visible={visible}
      title="管理资源"
      okText="保存"
      cancelText="取消"
      onCancel={onCancel}
      onOk={submit}
      maskClosable={false}
      className="myModal"
      transitionName=""
    >
      <div pa-15="" className={cn("ct")}>
        <p className={cn("ct__header")}>基础信息</p>
        <section ph-30="" mb-25="">
          <div pv-5="">
            <span co-se="" mr-8="">
              资源类型:
            </span>
            <span co-se="">{type}</span>
          </div>
          <div pv-5="" di-fl="">
            <span co-se="" mr-8="">
              资源名称:
            </span>
            <Input
              style={{ flex: 1 }}
              placeholder="Basic usage"
              value={name}
              onChange={handleNameChange}
            />
          </div>
        </section>

        <p className={cn("ct__header")}>权限设置</p>
        <section ph-30="" mb-15="">
          <div di-fl="" st="">
            <p mv-12="" co-se="" mr-8="">
              权限类别:
            </p>
            <div>
              <Radio.Group onChange={handleAuthorityChange} value={authority}>
                <Radio value={0}>开放</Radio>
                <Radio value={1}>授权</Radio>
                <Radio value={2}>密码</Radio>
              </Radio.Group>
            </div>
          </div>

          {/* 权限1: 授权 */}
          {authority === 1 && (
            <div className={cn("ct__box")} bo-sh="">
              <p pb-6="">管理授权者:</p>
              <div className={cn("ct__boxInput")}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="请输入用户名或名称搜索"
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={onUserSelectSearch}
                  onChange={onUserSelectChange}
                  notFoundContent={null}
                  value={selectUserId}
                >
                  {users.map(({ id, phone, account, username }) => (
                    <Option key={id} value={id}>
                      - {username} <span co-se="">[{account || phone}]</span>
                    </Option>
                  ))}
                </Select>

                <PlusSquareFilled
                  className={cn("ct__boxIcon")}
                  onClick={handleAllowedUserAdd}
                />
              </div>
              <div>
                {allowedUsers.map(({ id, username, account, phone }, index) => (
                  <p fo-si-s="" co-pr="" pv-2="" key={index}>
                    <span
                      className={cn("addUser__text")}
                      onClick={() => handleAllowedUserDel(id)}
                    >
                      <CloseOutlined
                        className={cn("addUser__del")}
                        style={{ color: "red" }}
                      />
                      - {username}{" "}
                      <span co-se="" pl-3="">
                        [{account || phone}]
                      </span>
                    </span>
                  </p>
                ))}
              </div>
            </div>
          )}

          {/* 权限2: 密码 */}
          {authority === 2 && (
            <div className={cn("ct__box")} bo-sh="">
              <p pb-6="">设置密码:</p>
              <Input.Password
                placeholder="请输入密码"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
          )}
        </section>
      </div>
    </Modal>
  );
};

export default ResourceManageDetail;
