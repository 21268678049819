import request from "../config/request";

const PREFIX = "/public";

/**
 * 获取置顶作品
 */
async function getPublishTopWorkMap() {
  let rs = await request({
    method: "GET",
    url: `${PREFIX}/getPublishTopWorkMap`,
  });

  return rs;
}

/**
 * 添加置顶作品
 */
async function addPublishTopWork({ key, id }) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/addPublishTopWork`,
    data: {
      key, id
    },
  });

  return rs;
}

/**
 * 更新置顶作品
 */
async function updatePublishTopWork({ key, id, newId }) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/updatePublishTopWork`,
    data: {
      key, id, newId
    },
  });

  return rs;
}

/**
 * 删除置顶作品
 */
async function delPublishTopWork({ key, id }) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/delPublishTopWork`,
    data: {
      key, id
    },
  });

  return rs;
}

/**
 * 移动置顶作品
 */
async function movePublishTopWork({ startKey, startId, endKey, endId }) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/movePublishTopWork`,
    data: {
      startKey, startId, endKey, endId
    },
  });

  return rs;
}

export { getPublishTopWorkMap, addPublishTopWork, updatePublishTopWork, delPublishTopWork, movePublishTopWork };
