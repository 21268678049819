import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  DatePicker,
  Upload,
  Button,
  message,
  InputNumber,
  Modal,
  Select,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import uploader from "../../utils/oss/kids-resource-uploader";
import {
  calcRestDay,
  formatCloudResource2ComponentSource,
  formatResource2RelativePath,
  parseHash,
  parseSearch,
  randomId,
} from "../../utils";
import { getTempUrl } from "../../utils/oss/config";
import BackBtn from "../../components/BackBtn";
import {
  addWxpaySubject,
  getWxpaySubject,
  getWxpaySubjectList,
  updateWxpaySubject,
} from "../../api/wxpaySubject";
import moment from "antd/node_modules/moment";
import FormControl from "./FormControl";
import { formControlMode, mockControlList } from "./config";
import useDebounce from "../../hook/useDebounce";

const mobilePhoneImage = require("../../assets/mobile-phone.png");

const { Option } = Select;

const PaySubjectManageAdd = () => {
  const history = useHistory();

  // 模式判断
  const isEditMode = history.location.pathname.split("/").includes("edit")
    ? true
    : false;

  const { id } = parseSearch();

  const btnText = isEditMode ? "编辑" : "新增";

  const initialValues = {
    name: "",
    endDate: "",
    money: "",
    maxPeople: "",
    qrcodeList: [],
    coverList: [],
  };

  const [loading, setLoading] = useState(false);

  // 联系人 默认控件列表
  const [defaultContactsControlList, setDefaultContactsControlList] = useState(
    []
  );
  // 联系人 控件列表
  const [contactsControlList, setContactsControlList] = useState([]);

  // 学生 默认控件列表
  const [defaultChildrenControlList, setDefaultChildrenControlList] = useState(
    []
  );

  // 学生 控件列表
  const [childrenControlList, setChildrenControlList] = useState([]);

  // 主题导入弹窗
  const [importSubjectModalOpen, setImportSubjectModalOpen] = useState(false);

  // 主题搜索列表
  const [importSubjectList, setImportSubjectList] = useState([]);

  // 主题选中内容
  const [importSubjectId, setImportSubjectId] = useState("");

  const [formRef] = Form.useForm();
  // const [contactsFormRef] = Form.useForm();
  // const [childrenFormRef] = Form.useForm();

  const debounceToGetSubjectImportList = useDebounce(
    toGetSubjectImportList,
    200,
    true
  );

  useEffect(async () => {
    if (id) {
      await toGetInfo();
    }

    await debounceToGetSubjectImportList();
  }, []);

  /** 获取主题信息（若编辑模式） */
  async function toGetInfo(targetId) {
    const curId = targetId || id;
    const info = await getWxpaySubject(curId);
    console.log(info);

    // 日期格式化
    info.endDate = info.endDate.slice(0, 10);

    let { name, endDate, money, maxPeople, cover, qrcode } = info;
    const { contactsControlList, childrenControlList } = info;

    // 格式化主题基础数据
    const coverList = formatCloudResource2ComponentSource(cover);
    const qrcodeList = formatCloudResource2ComponentSource(qrcode);
    endDate = moment(endDate, "YYYY-MM-DD");

    // 自定义数据注入
    setDefaultContactsControlList(contactsControlList);
    setDefaultChildrenControlList(childrenControlList);

    console.log({ name, endDate, money, maxPeople, coverList, qrcodeList });

    formRef.setFieldsValue({
      name,
      endDate,
      money,
      maxPeople,
      coverList,
      qrcodeList,
    });
  }

  /** 获取主题列表（主题导入用） */
  async function toGetSubjectImportList(keyword = "") {
    const data = await getWxpaySubjectList({
      page: 1,
      keyword,
    });
    setImportSubjectList(data.list);
  }

  // 上传二维码相关
  async function beforeQrcodeUpload(file) {
    setLoading(true);

    const { name } = file;
    const ext = name.split(".").pop();

    const filePath = `${randomId()}.${ext}`;
    await uploader.uploadToTempDir(filePath, file);

    const url = getTempUrl(filePath);

    setLoading(false);

    return Promise.resolve(file);
  }

  function onQrcodeRemove() {
    // setQrcodeList([]);
  }

  function customQrcodeRequest(options) {
    options.onSuccess(null, options.file);
  }

  // 上传背景相关
  async function beforeCoverUpload(file) {
    setLoading(true);

    const { name } = file;
    const ext = name.split(".").pop();

    const filePath = `${randomId()}.${ext}`;
    await uploader.uploadToTempDir(filePath, file);

    const url = getTempUrl(filePath);

    setLoading(false);

    return Promise.resolve(file);
  }

  function onCoverRemove() {
    // setCoverList([]);
  }

  function customCoverRequest(options) {
    options.onSuccess(null, options.file);
  }

  // 保存 | 编辑主题
  async function createSubject(values) {
    // 至少保证 联系人 学生 控件任意有项
    if (contactsControlList.length === 0 && childrenControlList.length === 0) {
      message.warn("右侧主题内容至少定义 1 项");
      return;
    }

    console.log(values, contactsControlList, childrenControlList);

    // 结构关键数据
    const { name, endDate, money, maxPeople, qrcodeList, coverList } = values;

    // 数据格式化（qrcodeList, coverList 转为 绝对路径
    const qrcode = formatResource2RelativePath(qrcodeList)[0];
    const cover = formatResource2RelativePath(coverList)[0];

    const data = {
      name,
      end_date: endDate.format("yyyy-MM-DD hh:mm:ss"),
      cover,
      qrcode,
      max_people: maxPeople,
      total: money * 100, // 分
      contacts_control_list: JSON.stringify(contactsControlList),
      children_control_list: JSON.stringify(childrenControlList),
    };

    console.log((window.data = data));

    // 检测为添加模式
    if (!isEditMode) {
      await addWxpaySubject(data);
      message.success("创建成功");
    } else {
      // 编辑模式
      await updateWxpaySubject(id, data);
      message.success("编辑成功");
    }
    history.goBack();
  }

  // 正则校验未通过（目前仅测试用）
  function createSubectFailed(errorInfo) {
    console.log(errorInfo);
    // contactsFormRef
    //   .validateFields()
    //   .then((values) => {
    //     console.log(values);
    //   })
    //   .catch((errorInfo) => console.log(errorInfo));
  }

  /** 变化 - 最新的联系人信息列表 */
  function onContactsControlListChange(list) {
    setContactsControlList(list);
    console.log(list);
  }

  /** 变化 - 最新的学生信息列表 */
  function onChildrenControlListChange(list) {
    setChildrenControlList(list);
    console.log(list);
  }

  /** 点击 - 主题导入 */
  function handleSubjectImportClick() {
    setImportSubjectModalOpen(true);
  }

  /** 点击 - 主题弹窗确认 */
  async function handleImportSubjectModalOk() {
    if (importSubjectId) {
      // 开始导入
      await toGetInfo(importSubjectId);
    }

    setImportSubjectId("");
    setImportSubjectModalOpen(false);
  }

  /** 点击 - 主题弹窗取消 */
  function handleImportSubjectModalCancel() {
    setImportSubjectId("");
    setImportSubjectModalOpen(false);
  }

  /** 变化 - 主题导入-选中 */
  function onSubjectImportSelected(id) {
    setImportSubjectId(id);
  }

  /** 点击 - 主题导入-触发搜索 */
  function onSubjectImportSearch(value) {
    debounceToGetSubjectImportList(value);
  }

  const uploadButton = (
    <div>{loading ? <LoadingOutlined /> : <PlusOutlined />}</div>
  );

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    const { fileList } = e;

    if (fileList) {
      return fileList.map((i) => ({
        relativePath: i.relativePath,
        url: i.absolutePath,
      }));
    }
  };

  return (
    <>
      <BackBtn />
      <div style={{ minWidth: "960px", display: "flex" }}>
        {/* 1、表单内容 */}
        <div style={{ flex: "0 0 420px" }} mr-35="">
          <p className="kx-label">1、主题基础信息（固定）</p>
          <Form
            form={formRef}
            initialValues={initialValues}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            autoComplete="off"
            onFinish={createSubject}
            onFinishFailed={createSubectFailed}
          >
            <Form.Item
              label="主题名称"
              name="name"
              rules={[{ required: true, message: "请输入标题" }]}
            >
              <Input style={{ width: 280 }} />
            </Form.Item>

            <Form.Item
              label="报名截止"
              name="endDate"
              rules={[{ required: true, message: "请选择报名截止日期" }]}
            >
              <DatePicker style={{ width: 200 }} format="YYYY-MM-DD" />
            </Form.Item>

            <Form.Item label="人数限制" required>
              <Form.Item
                name="maxPeople"
                noStyle
                rules={[
                  { required: true, pattern: /\d+/, message: "请填写人数限制" },
                ]}
              >
                <InputNumber min={0} max={9999} />
              </Form.Item>
              <p co-se="">人数填0, 则不限制报名人数</p>
            </Form.Item>

            <Form.Item label="主题金额" required>
              <Form.Item
                name="money"
                noStyle
                rules={[{ required: true, message: "请输入金额" }]}
              >
                <Input style={{ width: 200 }} />
              </Form.Item>
              <span co-se="" ml-3="">
                元
              </span>
            </Form.Item>

            <Form.Item label="联系二维码" required>
              <Form.Item
                name="qrcodeList"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                  {
                    type: "array",
                    required: true,
                    message: "请上传联系二维码",
                  },
                ]}
              >
                <Upload
                  name="qrcodeList"
                  listType="picture-card"
                  maxCount={1}
                  beforeUpload={beforeQrcodeUpload}
                  customRequest={customQrcodeRequest}
                  onRemove={onQrcodeRemove}
                >
                  {uploadButton}
                </Upload>
              </Form.Item>
              <div co-se="" fo-si-s="">
                - 支付完成后，弹出的二维码
              </div>
            </Form.Item>

            <Form.Item label="背景图片" required>
              <Form.Item
                name="coverList"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                  {
                    type: "array",
                    required: true,
                    message: "请上传背景图片",
                  },
                ]}
              >
                <Upload
                  name="coverList"
                  listType="picture-card"
                  maxCount={1}
                  beforeUpload={beforeCoverUpload}
                  customRequest={customCoverRequest}
                  onRemove={onCoverRemove}
                >
                  {uploadButton}
                </Upload>
              </Form.Item>
              <div co-se="" fo-si-s="">
                - 作为主题的展示背景。
              </div>
            </Form.Item>
            <div>
              <Button
                type="primary"
                htmlType="submit"
                size="middle"
                style={{
                  width: "220px",
                  height: "40px",
                  marginLeft: "75px",
                }}
              >
                {btnText}主题
              </Button>
            </div>
          </Form>
        </div>

        {/* 2、效果预览 */}
        <div
          style={{
            flex: 1,
            maxWidth: "580px",
            pointerEvents: isEditMode ? "none" : "all",
          }}
        >
          <p className="kx-label">
            2、主题内容信息（{isEditMode? <span co-wa="">无法修改</span>:"自定义"}）
            <span
              style={{
                color: "#EC8325",
              }}
              fl-ri=""
              ho-te-un=""
              onClick={handleSubjectImportClick}
            >
              主题导入
            </span>
          </p>

          <div
            style={{
              maxWidth: "420px",
            }}
          >
            <section ml-24="">
              <p className="kx-label" di-in-bl="">
                2.1 联系人信息项
              </p>
              <FormControl
                key={defaultContactsControlList}
                mode={formControlMode.edit}
                defaultValue={defaultContactsControlList}
                onChange={onContactsControlListChange}
                // ref={contactsFormRef}
              />
            </section>

            <section ml-24="">
              <p className="kx-label" di-in-bl="">
                2.2 学生信息项
              </p>
              <FormControl
                key={defaultChildrenControlList}
                mode={formControlMode.edit}
                defaultValue={defaultChildrenControlList}
                onChange={onChildrenControlListChange}
                // ref={childrenFormRef}
              />
            </section>
          </div>
        </div>
      </div>

      {/* 导入主题弹窗 */}
      <Modal
        title="导入主题"
        visible={importSubjectModalOpen}
        onOk={handleImportSubjectModalOk}
        onCancel={handleImportSubjectModalCancel}
        maskClosable={false}
        width="360px"
      >
        <Select
          style={{ width: "180px" }}
          showSearch
          placeholder="输入主题名称搜索"
          onChange={onSubjectImportSelected}
          onSearch={onSubjectImportSearch}
          filterOption={false}
        >
          {importSubjectList.map((i) => (
            <Option key={i.id} value={i.id}>
              {i.name}
            </Option>
          ))}
        </Select>
      </Modal>
    </>
  );
};

export default PaySubjectManageAdd;
