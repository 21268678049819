/**
 * @description InputModal 封装
 * 因为 input 模态框弹出 使用的场景比较多，所以单独抽象，避免维护许多 state
 */

import { Input } from "antd";
import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import BasicModal from "./BasicModal";

const InputModal = (props) => {
  const { type, title, okText, onOk, defaultValue, defaultPlaceholder } = props;

  const [value, setValue] = useState(defaultValue);

  const inputRef = useRef(null);
  const basicModalRef = useRef(null);

  // 获取焦点
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  function onValueChange(e) {
    setValue(e.target.value);
  }

  async function onPressEnter() {
    const {
      current: { emitParentOk },
    } = basicModalRef;

    // 触发父元素 OK 回调
    emitParentOk(value);
  }

  let CurInput = Input;

  // 如果是密码模式
  if (type === "password") {
    CurInput = Input.Password;
  }

  return (
    <BasicModal
      title={title}
      okText={okText}
      onOk={() => onOk(value)}
      ref={basicModalRef}
    >
      <CurInput
        type={type}
        placeholder={defaultPlaceholder}
        value={value}
        onChange={onValueChange}
        onPressEnter={onPressEnter}
        ref={inputRef}
      />
    </BasicModal>
  );
};

function createInputModal({
  type = "",
  title = "标题",
  defaultValue = "",
  defaultPlaceholder = "",
  okText = "确认",
  onOk = () => {},
}) {
  const div = document.createElement("div");
  document.body.appendChild(div);

  ReactDOM.render(
    <InputModal
      type={type}
      title={title}
      okText={okText}
      onOk={onOk}
      defaultValue={defaultValue}
      defaultPlaceholder={defaultPlaceholder}
    />,
    div
  );
}

export { createInputModal };
