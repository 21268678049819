import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Upload, Button } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import uploader from "../../utils/oss/kids-resource-uploader";
import { parseHash, parseSearch, randomId } from "../../utils";
import { getTempUrl } from "../../utils/oss/config";
import { text2QRCode } from "../../utils/qrcode";
import { createBasicModal } from "../../core/BasicModal";
import { updateWXShareEntity } from "../../utils/weixin/jssdk-core";
import { checkUrl } from "../../utils/validate";

const defaultImage = require("./defaultImage2.png");

const WxShareCustom = () => {
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [desc, setDesc] = useState("");
  const [imgUrl, setImgUrl] = useState("");

  const [shareMode, setShareMode] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const hashParams = parseHash();
    let { imgUrl, title, link, desc, mode } = hashParams;

    imgUrl = decodeURIComponent(imgUrl);
    title = decodeURIComponent(title);
    link = decodeURIComponent(link);
    desc = decodeURIComponent(desc);

    // 如果是移动端 处于分享状态
    if (mode === "share") {
      setShareMode(true);
      setLink(link);
      setDesc(desc);
      setTitle(title);
      setImgUrl(imgUrl);

      // 更新微信分享实体
      updateWXShareEntity({
        title,
        desc,
        link,
        imgUrl,
      });
    }
  }, []);

  function handleLinkChange(e) {
    setLink(e.target.value);
  }

  function handleTitleChange(e) {
    setTitle(e.target.value);
  }

  function handleDescChange(e) {
    setDesc(e.target.value);
  }

  async function beforeUpload(file) {
    setLoading(true);

    const { name } = file;
    const ext = name.split(".").pop();

    const filePath = `${randomId()}.${ext}`;
    await uploader.uploadToTempDir(filePath, file);

    const url = getTempUrl(filePath);
    setImgUrl(url);

    setLoading(false);

    return Promise.resolve(file);
  }

  function onRemove() {
    setImgUrl("");
  }

  function customRequest(options) {
    options.onSuccess(null, options.file);
  }

  // 生成二维码
  async function genQRCode(data) {
    // 将标题 描述 封面 转成拼接参数
    let { href, origin } = window.location;
    // href = "https://admin.k12-code.com/setting/wx-share-custom"
    // origin = "https://admin.k12-code.com"

    // 这里我们通过 Link 定制化，全部访问 ?redirect=链接 内部逻辑进行跳转即可。
    const redirectLink = encodeURIComponent(`${origin}/?redirect=${link}`);

    const hash = `#link=${redirectLink}&title=${title}&desc=${desc}&imgUrl=${imgUrl}&mode=share`;
    const wholeUrl = href + hash;

    const qrcodeImage = await text2QRCode(wholeUrl);
    console.log(wholeUrl);

    createBasicModal({
      title: "微信扫描当前二维码",
      footer: null,
      children: (
        <div di-fl="">
          <img src={qrcodeImage} width="200" />
          <p co-da="" ml-10="">
            扫码后，通过右上角按钮进行转发 分享或转发即可。
          </p>
        </div>
      ),
    });
  }

  const linkRules = [
    {
      required: true,
      async validator(_, url) {
        if (!url) {
          return Promise.reject("请输入链接");
        }

        if (!checkUrl(url)) {
          return Promise.reject("链接格式错误，以 http 或 https 开头");
        }
        return Promise.resolve();
      },
    },
  ];

  const uploadButton = (
    <div>{loading ? <LoadingOutlined /> : <PlusOutlined />}</div>
  );

  // 渲染封面
  const curImgUrl = imgUrl || defaultImage;

  // 如果分享模式
  const shareModeStyle = {
    position: "fixed",
    zIndex: 999999,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    marginTop: "-30vh",
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div style={{ width: "960px", display: "flex" }}>
      {/* 基础信息 */}
      <div style={{ flex: 1 }} mr-35="">
        <p className="kx-label">基础信息</p>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          onFinish={genQRCode}
        >
          {/* <p
            co-da=""
            mv-5=""
            style={{
              marginLeft: "75px",
              marginBottom: "10px",
              marginTop: "0px",
            }}
          >
            任意链接均可定制
          </p> */}
          <Form.Item label="跳转链接" name="link" rules={linkRules}>
            <Input value={link} onChange={handleLinkChange} />
          </Form.Item>

          <Form.Item
            label="标题"
            name="title"
            rules={[{ required: true, message: "请输入标题" }]}
          >
            <Input value={title} onChange={handleTitleChange} />
          </Form.Item>

          <Form.Item label="描述">
            <Input value={desc} onChange={handleDescChange} />
          </Form.Item>

          <Form.Item label="封面上传">
            <Upload
              name="avatar"
              listType="picture-card"
              maxCount={1}
              beforeUpload={beforeUpload}
              customRequest={customRequest}
              onRemove={onRemove}
            >
              {!imgUrl && uploadButton}
            </Upload>
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            size="middle"
            style={{
              marginLeft: "75px",
            }}
          >
            生成二维码
          </Button>
        </Form>
      </div>

      {/* 效果预览 */}
      <div
        style={Object.assign(
          {
            flex: 1,
          },
          shareMode ? shareModeStyle : {}
        )}
      >
        {shareMode ? (
          <p mv-10="" co-da="">
            请点击右上角按钮,进行微信分享
          </p>
        ) : (
          <p className="kx-label">效果预览</p>
        )}

        <div
          style={{
            position: "relative",
            borderRadius: "5px",
            background: "#fff",
            width: "260px",
            height: "160px",
            padding: "14px 18px",
            boxShadow: "0 0 3px 1px var(--color-shadow)",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              color: "#333",
              fontWeight: "bold",
            }}
            mb-5=""
          >
            {title}
          </p>
          <p co-se="" fo-si-s="">
            {desc}
          </p>
          <img
            style={{
              position: "absolute",
              right: "16px",
              bottom: "16px",
              width: "60px",
              height: "60px",
              objectFit: "cover",
              borderRadius: "2px",
            }}
            fl-ri=""
            src={curImgUrl}
          />
        </div>
      </div>
    </div>
  );
};

export default WxShareCustom;
