
const ENV = process.env.NODE_ENV

// 阿里云OSS
let OSSInfo;

// 区分环境变量
if (ENV === 'development') {
  OSSInfo = {
    TEMP_PATH: 'temp',
    region: 'oss-cn-hangzhou',
    accessKeyId: 'LTAIGzMXuPTFuVzx',
    accessKeySecret: '2UgtRkeu11SflmGusvJMsJMKWFbcEH',
    secure: true,
    bucket: 'shou-upload-test'
  };

} else {
  OSSInfo = {
    TEMP_PATH: 'temp',
    region: 'oss-cn-hangzhou',
    accessKeyId: 'LTAI5tBUTDhT73fPXCjBgYuC',
    accessKeySecret: 'DdzM7FOhnQygsSpvDVM7HyxvJvMHhL',
    secure: true,
    bucket: 'kaisiao-users-bucket'
  }

}

export {
  OSSInfo,
}