import request from "../config/request";
import {message} from 'antd'

const PREFIX = "/lessonResource";

/**
 * 新增
 * @param {*} param0
 */
async function addLessonResource(data) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/addLessonResource`,
    data,
  });

  return rs;
}

/**
 * 删除资源
 * @param {array} ids
 */
async function deleteLessonResource(ids) {

  let rs = await request({
    method: "POST",
    url: `${PREFIX}/deleteLessonResource`,
    data: {
      ids,
    },
  });

  return rs;
}

/**
 * 删除资源
 * @param {array} ids ids
 * @param {string} targetId targetId
 */
 async function removeLessonResource(ids, targetId) {

  let rs = await request({
    method: "POST",
    url: `${PREFIX}/removeLessonResource`,
    data: {
      ids,
      targetId
    },
  });

  return rs;
}

/**
 * 获取资源
 * @param {string} id id
 */
 async function getLessonResource(id) {
  let rs = await request({
    method: "GET",
    url: `${PREFIX}/getLessonResource`,
    params: {
      id,
    },
  });

  return rs;
}

/**
 * 获取全部课程资源
 */
async function getAllLessonResource() {
  let rs = await request({
    method: "GET",
    url: `${PREFIX}/getAllLessonResource`,
  });

  return rs;
}

/**
 * 变更资源 - 通用方法
 * @param {string} id
 * @param {object} params
 */
async function updateLessonResource(id, params) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/updateLessonResource`,
    data: {
      id,
      ...params,
    },
  });

  return rs;
}

/**
 * 变更资源
 * @param {number} id
 * @param {number} tid
 * @returns
 */
async function updateLessonResourceOrder(id, tid) {
  // id 相等，则不做变更。
  if (id === tid) {
    return;
  }
  
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/updateLessonResourceOrder`,
    data: {
      id,
      tid
    },
  });

  return rs;
}

/**
 * 变更资源父文件夹
 * @param {array} ids
 * @param {number} parentFolderId
 * @returns
 */
 async function updateLessonResourceParentFolderId(ids, parentFolderId) {

  let rs = await request({
    method: "POST",
    url: `${PREFIX}/updateLessonResourceParentFolderId`,
    data: {
      ids,
      parentFolderId
    },
  });

  return rs;
}

/**
 * 变更资源名称
 * @param {string} id
 * @param {string} name
 * @returns
 */
 async function updateLessonResourceName(id, name) {

  let rs = await request({
    method: "POST",
    url: `${PREFIX}/updateLessonResourceName`,
    data: {
      id,
      name
    },
  });

  return rs;
}

export {
  addLessonResource,
  getLessonResource,
  getAllLessonResource,
  deleteLessonResource,
  removeLessonResource,
  updateLessonResource,
  updateLessonResourceOrder,
  updateLessonResourceParentFolderId,
  updateLessonResourceName
};
