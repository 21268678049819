import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames/bind";
import styles from "./index.module.scss";
import BackBtn from "../../components/BackBtn";
import OpenResource from "../../components/OpenResource";
import PayOrderTable from "../PayOrderManage/table";
import { parsePathId } from "../../utils";
import { getExistTotal, getWxpaySubject } from "../../api/wxpaySubject";
import { Button } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import XLSX from "./xlsx.mini.min.js";

const cn = classnames.bind(styles);

const defaultCoverImage = require("../../assets/default-cover.jpg");

const PaySubjectManageDetail = () => {
  const id = parsePathId();
  const [info, setInfo] = useState("");
  const [existTotal, seteExistTotal] = useState("");

  const tableRef = useRef(null);

  useEffect(async () => {
    // 主题信息
    const info = await getWxpaySubject(id);

    // 已报名人数
    const existTotal = await getExistTotal(id);

    seteExistTotal(existTotal);

    // 过滤
    if (!info.cover) {
      info.cover = defaultCoverImage;
    }

    setInfo(info);
  }, []);

  /** 导出 excel */
  function handleExportExcel() {
    const { name } = info;
    const { orderList } = tableRef.current;

    const now = new Date();
    const date =
      now.getFullYear() +
      "-" +
      (now.getMonth() + 1 < 10 ? "0" : "") +
      (now.getMonth() + 1) +
      "-" +
      (now.getDate() - 1 < 10 ? "0" : "") +
      (now.getDate() - 1);

    const arr = orderList.map((i, index) => {
      const contactsInfoText = i.contactsFieldList
        .map(
          (fieldInfo, idx) =>
            `${idx + 1}. ` +
            Object.keys(fieldInfo)
              .map((k) => fieldInfo[k])
              .join(" - ")
        )
        .join("\n");

      const childrenInfoText = i.childrenFieldList
        .map(
          (fieldInfo, idx) =>
            `${idx + 1}. ` +
            Object.keys(fieldInfo)
              .map((k) => fieldInfo[k] || '/')
              .join(" - ")
        )
        .join("\n");

      return {
        序号: index + 1,
        内部订单号: i.outTradeNo,
        主题名称: name,
        支付人微信名: i.nickname,
        报名人数: i.childNumber,
        联系人信息: contactsInfoText,
        报名学生信息: childrenInfoText,
        付款情况: i.money,
        付款时间: i.successDate,
        退款记录:
          i.refundList
            .filter((i) => i.refundStatus === "SUCCESS")
            .reduce((total, j) => total + j.refund, 0) / 100,
      };
    });

    var ws =
      XLSX.utils.json_to_sheet(arr); /* 新建空workbook，然后加入worksheet */

    // 设置列宽
    ws["!cols"] = [
      { wch: 4 },
      { wch: 14 },
      { wch: 14 },
      { wch: 10 },
      { wch: 14 },
      { wch: 40 },
      { wch: 40 },
      { wch: 10 },
      { wch: 18 },
      { wch: 10 },
    ];

    var wb = XLSX.utils.book_new(); /*新建book*/
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      "sheet1"
    ); /* 生成xlsx文件(book,sheet数据,sheet命名) */
    XLSX.writeFile(
      wb,
      `《${name}》-${date}.xlsx`
    ); /*写文件(book,xlsx文件名称)*/
  }
  console.log(info)
  return (
    <>
      <BackBtn />
      <div className={cn("ct")} mt-10="">
        <p className="kx-label">1、支付主题信息</p>
        <div className={cn("ct-main")}>
          <div style={{ flex: `0 0 340px` }}>
            <div className={cn("ct-item")}>
              <p>主题类型：</p>
              <p>{info.type === 0 ? "活动报名类" : ""}</p>
            </div>
            <div className={cn("ct-item")}>
              <p>主题名称：</p>
              <p co-pr="">{info.name}</p>
            </div>
            <div className={cn("ct-item")}>
              <p>报名截止：</p>
              <p>{info.endDate}</p>
              {info.restDay > 0 ? (
                <p ml-4="" fo-si="" co-se="">
                  (剩余{info.restDay}天)
                </p>
              ) : (
                <p ml-4="" fo-si="" co-da="">
                  (已截止)
                </p>
              )}
            </div>
            <div className={cn("ct-item")}>
              <p>人数限制：</p>
              {!info.maxPeople ? (
                <p co-se="">不限制</p>
              ) : (
                <p co-se="">{info.maxPeople}</p>
              )}
            </div>
            <div className={cn("ct-item")}>
              <p>主题金额：</p>
              <p co-wa="" fo-si-l="">
                {info.money}元
              </p>
            </div>
          </div>

          <OpenResource src={info.cover}>
            <div className={cn("ct-item")}>
              <p>背景图片: </p>
              <p>
                <img
                  className={cn("ct-item-cover")}
                  width="120"
                  src={info.cover}
                  alt=""
                />
              </p>
            </div>
          </OpenResource>

          <OpenResource src={info.qrcode}>
            <div className={cn("ct-item")}>
              <p>联系二维码: </p>
              {info.qrcode && (
                <p>
                  <img
                    className={cn("ct-item-cover")}
                    width="120"
                    src={info.qrcode}
                    alt=""
                  />
                </p>
              )}
            </div>
          </OpenResource>
        </div>

        <p className="kx-label">
          2、支付列表 (已报名学生:
          <span co-pr="" fo-we-bo="">
            {existTotal}
          </span>
          人)
          <Button
            type="primary"
            style={{ float: "right", opacity: 1 }}
            onClick={handleExportExcel}
          >
            导出excel
            <ExportOutlined />
          </Button>
        </p>

        <div pv-20="" style={{ maxWidth: "1220px" }}>
          <PayOrderTable
            expandRows={true}
            subjectId={id}
            subjectInfo={info}
            pageSize={9999}
            ref={tableRef}
          />
        </div>
      </div>
    </>
  );
};

export default PaySubjectManageDetail;
