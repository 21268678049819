import { defaultPageSize } from "../config";
import request from "../config/request";

const PREFIX = "/wxpayOrder";

/**
 * 新增
 * @param {*} param0
 */
async function addWxpayOrder(data) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/addWxpayOrder`,
    data,
  });

  return rs;
}

/**
 * 检测用户是否已付款
 * @param {*} param0
 */
async function checkWxUserPaid(openid, subjectId) {
  let rs = await request({
    method: "GET",
    url: `${PREFIX}/checkWxUserPaid`,
    params: {
      openid,
      subjectId
    },
  });

  return rs;
}

/**
 * 已读
 * @param {*} param0
 */
async function setIsReadWxpayOrder(id) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/setIsReadWxpayOrder`,
    data: {
      id
    },
  });

  return rs;
}

/**
 *未读
 * @param {*} param0
 */
 async function setUnreadWxpayOrder(id) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/setUnreadWxpayOrder`,
    data: {
      id
    },
  });

  return rs;
}

/**
 * 设置订单状态
 * @param {number} id
 * @param {number} status
 */
 async function setWxpayOrderStatus(id,status) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/setWxpayOrderStatus`,
    data: {
      id,
      status
    },
  });

  return rs;
}

/**
 * 获取资源
 * @param {string} id id
 */
async function getWxpayOrder(id) {
  let rs = await request({
    method: "GET",
    url: `${PREFIX}/getWxpayOrder`,
    params: {
      id,
    },
  });

  return rs;
}

/**
 * 分页获取资源
 */
async function getWxpayOrderList({
  page,
  pageSize = defaultPageSize,
  status,
  keyword,
  subjectId
}) {
  let rs = await request({
    method: "GET",
    url: `${PREFIX}/getWxpayOrderList`,
    params: {
      page,
      pageSize,
      status,
      keyword,
      subjectId
    }
  });

  return rs;
}

export {
  addWxpayOrder,
  checkWxUserPaid,
  setIsReadWxpayOrder,
  setUnreadWxpayOrder,
  setWxpayOrderStatus,
  getWxpayOrder,
  getWxpayOrderList,
};
