import { defaultPageSize } from "../config";
import request from "../config/request";

const PREFIX = "/wxpaySubject";

/**
 * 新增
 * @param {*} param0
 */
async function addWxpaySubject(data) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/addWxpaySubject`,
    data,
  });

  return rs;
}

/**
 * 删除资源
 * @param {array} ids
 */
async function deleteWxpaySubject(ids) {

  let rs = await request({
    method: "POST",
    url: `${PREFIX}/deleteWxpaySubject`,
    data: {
      ids,
    },
  });

  return rs;
}

/**
 * 获取资源
 * @param {string} id id
 */
async function getWxpaySubject(id) {
  let rs = await request({
    method: "GET",
    url: `${PREFIX}/getWxpaySubject`,
    params: {
      id,
    },
  });

  return rs;
}

/**
 * 分页获取资源
 */
async function getWxpaySubjectList({
  page = 1,
  pageSize = defaultPageSize,
  status,
  keyword=""
}) {
  let rs = await request({
    method: "GET",
    url: `${PREFIX}/getWxpaySubjectList`,
    params: {
      page,
      pageSize,
      status,
      keyword,
    }
  });

  return rs;
}

/**
 * 变更资源 - 通用方法
 * @param {string} id
 * @param {object} params
 */
async function updateWxpaySubject(id, params) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/updateWxpaySubject`,
    data: {
      id,
      ...params,
    },
  });

  return rs;
}

/**
 * 变更资源名称
 * @param {string} id
 * @param {string} name
 * @returns
 */
async function updateWxpaySubjectName(id, name) {

  let rs = await request({
    method: "POST",
    url: `${PREFIX}/updateWxpaySubjectName`,
    data: {
      id,
      name
    },
  });

  return rs;
}

/**
 * 发布主题
 * @param {string} id
 */
async function publishWxpaySubject(id) {

  let rs = await request({
    method: "POST",
    url: `${PREFIX}/publishWxpaySubject`,
    data: {
      id,
    },
  });

  return rs;
}

/**
 * 关闭主题
 * @param {string} id
 */
async function closeWxpaySubject(id) {

  let rs = await request({
    method: "POST",
    url: `${PREFIX}/closeWxpaySubject`,
    data: {
      id,
    },
  });

  return rs;
}

/**
 * 发布主题
 * @param {string} id
 */
async function recoverWxpaySubject(id) {

  let rs = await request({
    method: "POST",
    url: `${PREFIX}/recoverWxpaySubject`,
    data: {
      id,
    },
  });

  return rs;
}

/**
 * 查看主题已付款人数
 * @param {string} subjectId
 */
async function getExistTotal(id) {

  let rs = await request({
    method: "GET",
    url: `${PREFIX}/getExistTotal`,
    params: {
      id,
    },
  });

  return rs;
}

export {
  addWxpaySubject,
  getWxpaySubject,
  getWxpaySubjectList,
  deleteWxpaySubject,
  updateWxpaySubject,
  updateWxpaySubjectName,
  publishWxpaySubject,
  closeWxpaySubject,
  recoverWxpaySubject,
  getExistTotal
};
