import axios from "axios";
import storage from "../utils/storage";
import { apiBaseUrl } from './env'
import { message } from 'antd'

// API地址
export const apiUrl = apiBaseUrl;

/** 资源地址（OSS） */
// export const fileUrl = 'https://nbkxpt-source-bucket.oss-cn-hangzhou.aliyuncs.com';

// create an axios instance
const service = axios.create({
  baseURL: apiUrl,
  timeout: 5000,
  withCredentials: true
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    const { errno, data, msg } = res
    if (errno !== 0) {
      // message.error(msg)
      return Promise.reject(res)
    }

    return res.data;
  },
  (error) => {
    console.log("err" + error); // for debug
    message.error('接口异常')
    return Promise.reject(error);
  }
);

export default service;
