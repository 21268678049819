import { defaultPageSize } from "../config";
import request from "../config/request";

const PREFIX = "/wxpayRefund";

/**
 * 新增
 * @param {*} param0
 */
async function addWxpayRefund(data) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/addWxpayRefund`,
    data,
  });

  return rs;
}

/**
 * 检测用户是否已付款
 * @param {*} param0
 */
async function checkWxUserPaid(openid, subjectId) {
  let rs = await request({
    method: "GET",
    url: `${PREFIX}/checkWxUserPaid`,
    params: {
      openid,
      subjectId
    },
  });

  return rs;
}

/**
 * 已读
 * @param {*} param0
 */
async function setIsReadWxpayRefund(id) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/setIsReadWxpayRefund`,
    data: {
      id
    },
  });

  return rs;
}

/**
 *未读
 * @param {*} param0
 */
 async function setUnreadWxpayRefund(id) {
  let rs = await request({
    method: "POST",
    url: `${PREFIX}/setUnreadWxpayRefund`,
    data: {
      id
    },
  });

  return rs;
}


// /**
//  * 删除资源
//  * @param {array} ids
//  */
// async function deleteWxpayRefund(ids) {

//   let rs = await request({
//     method: "POST",
//     url: `${PREFIX}/deleteWxpayRefund`,
//     data: {
//       ids,
//     },
//   });

//   return rs;
// }

/**
 * 获取资源
 * @param {string} id id
 */
async function getWxpayRefund(id) {
  let rs = await request({
    method: "GET",
    url: `${PREFIX}/getWxpayRefund`,
    params: {
      id,
    },
  });

  return rs;
}

/**
 * 分页获取资源
 */
async function getWxpayRefundList({
  page,
  pageSize = defaultPageSize,
  orderId
}) {
  let rs = await request({
    method: "GET",
    url: `${PREFIX}/getWxpayRefundList`,
    params: {
      page,
      pageSize,
      orderId,
    }
  });

  return rs;
}

// /**
//  * 变更资源 - 通用方法
//  * @param {string} id
//  * @param {object} params
//  */
// async function updateWxpayRefund(id, params) {
//   let rs = await request({
//     method: "POST",
//     url: `${PREFIX}/updateWxpayRefund`,
//     data: {
//       id,
//       ...params,
//     },
//   });

//   return rs;
// }

// /**
//  * 变更资源名称
//  * @param {string} id
//  * @param {string} name
//  * @returns
//  */
// async function updateWxpayRefundName(id, name) {

//   let rs = await request({
//     method: "POST",
//     url: `${PREFIX}/updateWxpayRefundName`,
//     data: {
//       id,
//       name
//     },
//   });

//   return rs;
// }

// /**
//  * 发布主题
//  * @param {string} id
//  */
// async function publishWxpayRefund(id) {

//   let rs = await request({
//     method: "POST",
//     url: `${PREFIX}/publishWxpayRefund`,
//     data: {
//       id,
//     },
//   });

//   return rs;
// }

// /**
//  * 发布主题
//  * @param {string} id
//  */
// async function closeWxpayRefund(id) {

//   let rs = await request({
//     method: "POST",
//     url: `${PREFIX}/closeWxpayRefund`,
//     data: {
//       id,
//     },
//   });

//   return rs;
// }

// /**
//  * 发布主题
//  * @param {string} id
//  */
// async function recoverWxpayRefund(id) {

//   let rs = await request({
//     method: "POST",
//     url: `${PREFIX}/recoverWxpayRefund`,
//     data: {
//       id,
//     },
//   });

//   return rs;
// }

// /**
//  * 微信统一下单触发
//  * @param {string} openid 用户微信openid
//  * @param {string} subjectId 主题id
//  */
//  async function payUnifiedorderWxpayRefund(openid, subjectId) {

//   let rs = await request({
//     method: "GET",
//     url: `${PREFIX}/payUnifiedorderWxpayRefund`,
//     params: {
//       openid,
//       subjectId
//     }
//   });

//   return rs;
// }

export {
  addWxpayRefund,
  checkWxUserPaid,
  setIsReadWxpayRefund,
  setUnreadWxpayRefund,
  getWxpayRefund,
  getWxpayRefundList,
  // deleteWxpayRefund,
  // updateWxpayRefund,
  // updateWxpayRefundName,
  // publishWxpayRefund,
  // closeWxpayRefund,
  // recoverWxpayRefund,
  // payUnifiedorderWxpayRefund
};
